import { axiosInstance } from "../Api";

export const getForgetPasswordApi = async (email) => {
 
  const encodedEmail = encodeURIComponent(email);
  console.log(encodedEmail, "encoded");
  try {
    const ForgetPassowrdApi = await axiosInstance.post(
      `/forget-password?email=${encodedEmail}`
    );

    return ForgetPassowrdApi?.data;
  } catch (err) {
    console.log("error", err);
  }
};

export const getResetPassword = async (
  resetCode,
  newPassword,
  confirmPassword
) => {
  try {
    const updateResetPassword = await axiosInstance.post(
      "/forget-password/reset?",
      {
        code: resetCode,
        new_password: newPassword,
        confirm_password: confirmPassword,
      }
      // `/forget-password/reset?code=${code}&new_password=${newPassword}&confirm_password=${confirmPassword}`
    );

    return updateResetPassword.data;
  } catch (err) {
    console.log("error", err);
  }
};

export const getValidationCode = async (code) => {
  try {

    const url = window.location.href;
    const parts = url.split("/");
    const code = parts[parts.length - 1];
    const updatevalidationApi = await axiosInstance.post(
      `/check-token-validity?code=${code}`
    );
 

    return updatevalidationApi.data;
  } catch (err) {
    console.log("error", err);
  }
};
