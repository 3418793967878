import { axiosInstance } from "../Api";

export const getProductApi = async () => {
  try {
    const productApi = await axiosInstance.post("/product/search");
    return productApi.data;
  } catch (err) {
    console.log("error".err);
  }
};

export const getProductSearch = async (searchQuery) => {
  try {
    const searchProduct = await axiosInstance.post(
      `/product/search?search=${searchQuery}`
    );

    return searchProduct.data;
  } catch (err) {
    console.log("error", err);
  }
};
