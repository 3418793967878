import { axiosInstance, axiosInstanceWithoutToken } from "../Api";

export const getRegister = async (data) => {
  try {
    const registerResponse = await axiosInstanceWithoutToken.post(
      "/register?",
      {
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        password: data.password,
        confirm_password: data.confirm_password,
      }
    );

    return registerResponse.data;
  } catch (err) {
    console.log("register api data failed:", err);
  }
};
