import React, { useState } from "react";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";

import { Button, Form, Modal } from "react-bootstrap";
import { removeTokenFormStorage } from "../../../Api/Api";

import "./popup.css";

const PopupsavechangePassword = ({ onClick }) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

 
  const handleClosePopup = () => {
    setShowPopup(false);
    onClick();
  };

  const handleLogout = () => {
    toast.success("Logout successful");
    removeTokenFormStorage();
    setIsDropdownOpen(false);
    handleClosePopup();
    navigate("/login");
  };

  const handleStayinLog = () => {
    navigate("/");
  };

  return (
    <div className="popup-container-profile">
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        style={{ marginTop: "9%" }}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Save Change Password.</Modal.Title>
          <RxCrossCircled className="icon-popup" onClick={handleClosePopup} />
        </Modal.Header>

        <Modal.Body>
          <Form className="popup-profile">
            <div className="popup-sav-change">
              <div className="popup-save-btn">
                <Button
                  style={{ backgroundColor: "#1A68B2" }}
                  onClick={handleStayinLog}
                >
                  Stay in Login
                </Button>
              </div>
              <div className="popup-logout-bt">
                <Button
                  style={{ backgroundColor: "#1A68B2" }}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
        
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default PopupsavechangePassword;
