import React, { createContext, useContext, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { getBrandApiId } from "../../Api/brandApi/BrandApi";

const BrandContext = createContext();

export const BrandContextApi = ({ children }) => {
  const navigate = useNavigate();
  const [brandProductId, setBrandProductId] = useState([]);
  const [activeBrand, setActiveBrand] = useState(null);

  const [noProductMessage, setNoProductMessage] = useState("");

  // const getUpdateBrandId = async (id) => {
  //   try {
  //     const updateBrandId = await getBrandApiId(id);
  //     console.log("updateBrandId", updateBrandId);

  //     // setBrandProductId((prevData) => [...prevData, ...updateBrandId?.data]);

  //     setBrandProductId(updateBrandId?.data);
  //     // const queryParams = updateBrandId.map((id) => `brand_id=${id}`).join("&");
  //     navigate(`/allproduct?${id}`);
  //     // navigate(`/allproduct/?brand_id=${id}`);
  //     setActiveBrand(id);

  //     console.log("updateBrandId.data.length", updateBrandId.data.length);
  //   } catch (err) {
  //     console.log("error", err);
  //   }
  // };

  // const getUpdateBrandId = async (id) => {
  //   try {
  //     const updateBrandId = await getBrandApiId(id);
  //     console.log("updateBrandId", updateBrandId);

  //     setBrandProductId(updateBrandId?.data);

  //     navigate(`/allproduct?${id}`);

  //     setActiveBrand(id);

  //     console.log("updateBrandId.data.length", updateBrandId.data.length);
  //   } catch (err) {
  //     console.log("error", err);
  //   }
  // };

  const getUpdateBrandId = async (id) => {
    try {
      let updateBrandId;

      if (Array.isArray(id)) {
        updateBrandId = await getBrandApiId(id);
      } else {
        updateBrandId = await getBrandApiId([id]);
      }

      console.log("updateBrandId.......", updateBrandId);
      if (updateBrandId.data.length === 0) {
        setNoProductMessage("No more products available");
      } else {
        setNoProductMessage("");
      }
      // Assuming setBrandProductId, navigate, and setActiveBrand are defined elsewhere in your code
      setBrandProductId(updateBrandId?.data);
      navigate(`/allproduct?brand_id=${id}`);
      setActiveBrand(id);

      console.log("updateBrandId.data.length", updateBrandId.data.length);
    } catch (err) {
      console.log("error", err);
    }
  };

  return (
    <BrandContext.Provider
      value={{
        brandProductId,
        activeBrand,
        getUpdateBrandId,
        setBrandProductId,
        noProductMessage,
      }}
    >
      {children}
    </BrandContext.Provider>
  );
};
export const useBrandContext = () => {
  return useContext(BrandContext);
};
