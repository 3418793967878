import React, { useEffect, useState } from "react";

import PopupList from "./PopUpDouble";
import { toast } from "react-toastify";

import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { RiDeleteBinLine } from "react-icons/ri";

import { useBadge } from "../../Product/BadageContext";
import { BreadCurmbs } from "../../Breadcrumbs/Breadcrumbs";
import { Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import { getDeleteEnquireProduct } from "../../../Api/enquireApi/EnquireDelete";

import "./EnquiryList.css";

export const EnquiryList = () => {
  const { productListData, deleteProduct } = useBadge();

  const [showPopup, setShowPopup] = useState(false);

  const handleClickPopup = () => {
    setShowPopup(!showPopup);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleDeleteEnquireProduct = async (id) => {
    try {
      const updateDeleteApi = await getDeleteEnquireProduct(id)
      if (updateDeleteApi.data) {
        deleteProduct(id);
        toast.success("successfully deleted!");
      } else {
        console.log("Invalid product ID in the response");
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    productListData.forEach((item) => {
    });
  }, [productListData]);

  return (
    <>
      <div>
        <BreadCurmbs title="Enquiry List " subtitle="Danfe Trading" />
        <section className={`section-padding enquirylist-section`}>
          <Container>
            <Row className="justify-content-center">
              <Col md={12} lg={10} xs={12}>
                <Card style={{ borderRadius: "0", marginBottom: "30px" }}>
                  <Card.Body style={{ padding: "0" }}>
                    <Card.Header
                      style={{
                        background: "#fafafa",
                        borderRadius: "0",
                        padding: "13px 15px",
                      }}
                    >
                      <Card.Title
                        style={{ marginBottom: "0", fontSize: "16px" }}
                      >
                        Product
                      </Card.Title>
                    </Card.Header>
                    {productListData && productListData.length > 0 ? (
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          {/* Array.isArray(productListData) && */}
                          {productListData.map((item, index) => (
                            <div
                              key={index}
                              className="product-list d-flex align-items-center"
                            >
                              <div className="product d-flex">
                                <div className="img-wrap">
                                  <img src={item?.product?.photo} alt="" />
                                </div>
                                <h6>{item?.product?.title}</h6>
                              </div>
                              <div className="action ">
                                {/* <span className="status">
                                  {item?.product?.stock}
                                </span> */}
                                <RiDeleteBinLine
                                  className="icon"
                                  onClick={() =>
                                    handleDeleteEnquireProduct(item?.id)
                                  }
                                />
                              </div>
                            </div>
                          ))}
                        </ListGroup.Item>
                      </ListGroup>
                    ) : (
                      <span className="no-product-added">
                        No product added..
                      </span>
                    )}
                  </Card.Body>
                </Card>

                <div className="btn-enquirylist">
                  <div
                    className="butn-wrap d-flex"
                    style={{ marginBottom: "96px" }}
                  >
                    <Link
                      to="/allproduct"
                      className="cl-button-dark"
                      style={{ backgroundColor: "#fafafa" }}
                    >
                      <IoIosArrowBack className="icon-left" />
                      Back to Shop
                    </Link>
                    <button
                      style={{ cursor: "pointer" }}
                      className="cl-button ms-auto"
                      onClick={handleClickPopup}
                    >
                      Enquire Now
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {showPopup && <PopupList onClick={handleClosePopup} />}
      </div>
    </>
  );
};
