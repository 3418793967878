import React from "react";
import { ClipLoader } from "react-spinners";

const Loading = () => {
  return (
    <div>
      <ClipLoader
        size={40}
        aria-label="Loading Spinner"
        data-testid="loader"
        color="rgb(26, 104, 178);"
      />
    </div>
  );
};

export default Loading;
