import React, { useEffect, useRef, useState } from "react";

import { Header } from "../Header";
import { FaRegUser } from "react-icons/fa";

import { toast } from "react-toastify";
import { Navbar } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import { BiSearch, BiX } from "react-icons/bi";

import { TbMessage2Question } from "react-icons/tb";
import Container from "react-bootstrap/esm/Container";
import { useBadge } from "../../Product/BadageContext";
import { removeTokenFormStorage } from "../../../Api/Api";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAddList } from "../../../Api/enquireApi/EnquireAddList";
import { getProductSearch } from "../../../Api/productApi/ProductApi";

import "../Header.css";

export const TopHeader = () => {
  const { badgeCount, setProductListData, setBadgeCount } = useBadge();

  const navigate = useNavigate();
  const location = useLocation();
  const navbarRef = useRef(null);
  const inputRef = useRef(null);

  const [products, setProducts] = useState([]);
  const [isToggled, setIsToggled] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const isLogged = !!localStorage.getItem("access_token");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogout = () => {
    toast.success("You have logged out successfully");
    removeTokenFormStorage();
    setIsDropdownOpen(null);
  };

  // const isProductPage =
  //   location.pathname === "/" ||
  //   location.pathname === "/allproduct" ||
  //   location.pathname === "/enquirylist" ||
  //   location.pathname === "/allbrands" ||
  //   location.pathname === "/allbrands" ||
  //   location.pathname === "/profile" ||
  //   location.pathname === "/product-detail";
  // console.log("isProductPage", isProductPage);

  const handleDoubleAddEnquire = async () => {
    try {
      if (!isLogged) {
        navigate("/login");
        return;
      }

      const updateDoubleEnquire = await getAddList();
      setProductListData(updateDoubleEnquire.data);
      setBadgeCount(updateDoubleEnquire?.data?.length);
      navigate("/enquirylist");
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (initialLoad && isLogged && location?.pathname === "/enquirylist") {
      handleDoubleAddEnquire();
      setInitialLoad(false);
    }
  }, [initialLoad, isLogged, location.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const updateDoubleEnquire = await getAddList();
        setProductListData(updateDoubleEnquire?.data);
        setBadgeCount(updateDoubleEnquire?.data?.length);
      } catch (error) {
        console.error("Error fetching data on page reload:", error);
      }
      setInitialLoad(false);
    };
    fetchData();
    setInitialLoad(false);
  }, []);

  useEffect(() => {
    const getsearchProduct = async () => {
      try {
        const updateSearch = await getProductSearch(searchQuery);
        setProducts(updateSearch.data);
      } catch (err) {
        console.log("error", err);
      }
    };
    getsearchProduct();
  }, [searchQuery]);

  const handleClickSearch = async () => {
    try {
      const updateSearch = await getProductSearch(searchQuery);
      setProducts(updateSearch.data);
      // navigate("/allproduct", { state: { products: updateSearch.data } });
      if (searchQuery.trim() !== "") {
        navigate("/allproduct", { state: { products: updateSearch.data } });
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const toggleOpenNavbar = () => {
    setIsToggled(!isToggled);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        navbarRef.current &&
        isToggled &&
        !navbarRef.current.contains(event.target)
        //&& !event.target.closest(".navbar")
      ) {
        setIsToggled(false);
      }
    };

    if (isToggled) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isToggled]);

  const handleKeyPress = async (e) => {
    if (e.key === "Enter") {
      await handleClickSearch();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setSearchQuery(""); // Clear the search query
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const currentURL = new URL(window.location.href);

  const past = currentURL.origin;

  const logoImage = `${past}/images/logo.png`;

  return (
    <>
      <div className={`dim-background${isToggled ? " dimmed" : ""}`}></div>
      <div className="top-header ">
        <Container>
          <div className="top-content d-flex justify-content-between align-items-center">
            <>
              {windowWidth > 991.5 ? (
                <>
                  <div className="nav-brand">
                    <div className="d-block">
                      <img src={logoImage} alt="logo" />
                    </div>
                  </div>
                  <Navbar
                    expand="lg"
                    className={`cl-navigation${isToggled ? " open" : ""}`}
                    ref={navbarRef}
                  >
                    <Navbar.Toggle
                      aria-controls="navbarScroll"
                      onClick={toggleOpenNavbar}
                    ></Navbar.Toggle>
                  </Navbar>
                </>
              ) : (
                <>
                  <Navbar
                    expand="lg"
                    className={`cl-navigation${isToggled ? " open" : ""}`}
                    ref={navbarRef}
                  >
                    <Navbar.Toggle
                      aria-controls="navbarScroll"
                      onClick={toggleOpenNavbar}
                    ></Navbar.Toggle>
                  </Navbar>
                  <div className="nav-brand">
                    <Link to="/" className="d-block">
                      <img
                        src="images/logo.png"
                        alt="logo"
                        className={
                          isLogged ? "logged-in-margin" : "not-logged-in-margin"
                        }
                      />
                    </Link>
                  </div>
                </>
              )}
            </>

            <div className="form-group  d-flex" ref={inputRef}>
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                className="form-control"
                onKeyPress={handleKeyPress}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className="icon-wrap">
                <BiSearch className="icon" onClick={handleClickSearch} />
              </div>
            </div>

            <Header
              isToggled={isToggled}
              toggleOpenNavbar={toggleOpenNavbar}
              setToggled={setIsToggled}
            />

            <ul className="icon-inline">
              <li className="custom-badge">
                <TbMessage2Question
                  className="icon"
                  style={{
                    fontSize: "27px",
                    cursor: "pointer",
                    color: "black",
                  }}
                  onClick={() => handleDoubleAddEnquire()}
                />
                <Badge bg="primary-50">{badgeCount}</Badge>
              </li>

              {isLogged ? (
                <li className="user-ac dropdown custom-dropdown">
                  <FaRegUser
                    className="user-icon"
                    style={{
                      fontSize: "19px",
                      marginTop: "-2px",
                      color: "black",
                    }}
                  />
                  <ul className="dropdown-menu ">
                    <li>
                      <Link to="/profile" className="dropdown-item">
                        Profile
                      </Link>
                    </li>
                    <li onClick={handleLogout}>
                      <Link to="/" className="dropdown-item">
                        Logout
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : (
                <>
                  <li>
                    <Link to="/login" className=" cl-button">
                      Login
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </Container>
      </div>
    </>
  );
};
