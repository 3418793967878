import React, { useEffect, useState } from "react";

import parse from "html-react-parser";
import { Link } from "react-router-dom";

import { IoIosArrowForward } from "react-icons/io";
import { Col, Container, Row } from "react-bootstrap";
import { getAboutApi } from "../../../Api/about/About";

import "./About.css";
import Loading from "../../loader/Loading";

export const About = () => {
  const [about, setAbout] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const aboutApi = async () => {
      try {
        const updatedAboutApi = await getAboutApi();
        setAbout(updatedAboutApi.data[0]);
        setLoading(false);
      } catch (err) {
        console.log("error", err);
      }
    };
    aboutApi();
  }, []);

  return (
    <>
      <section className="section-padding about-section">
        <Container>
          {loading || about?.length === 0 ? (
            <div className="loader-about">
              <Loading />
            </div>
          ) : (
            <Row>
              {about?.blocks?.map(
                (data, index) =>
                  index === 0 && (
                    <React.Fragment key={index}>
                      <Col md={6} className="left-wrap">
                        <div className="about-img">
                          <img src={data.image} alt="about-image" />
                        </div>
                      </Col>
                      <Col md={6} className="pl-50 right-wrap">
                        <div className="about-content">
                          <div className="cl-header p-header">
                            <span className="subtitle">ESTB.2023</span>
                            <h2 className="maintitle">{data.title}</h2>
                          </div>
                          <div className="content">
                            {/* <p className="italic">
                            Danfe Trading gives you a chance to quickly & easily
                            find the phone you want and have it delivered to
                            your home in no time.
                          </p> */}
                            <p>
                             
                              {data.content &&
                                typeof data.content === "string" &&
                                parse(`${data.content || ""}`)}
                            </p>
                            <Link to="/about" className="morebtn">
                              Read More{" "}
                              <IoIosArrowForward className="icon-aboutpage" />
                            </Link>
                            <ul className="info">
                              <li>
                                <div className="img-wrap">
                                  <img src="images/about/shield.png" />
                                </div>
                                <div className="text">
                                  <strong>200+</strong>
                                  <span>Products Sold</span>
                                </div>
                              </li>
                              <li>
                                <div className="img-wrap">
                                  <img src="images/about/world.png" />
                                </div>
                                <div className="text">
                                  <strong>800+</strong>
                                  <span>Vendors worldwide</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Col>
                    </React.Fragment>
                  )
              )}
            </Row>
          )}
        </Container>
      </section>
    </>
  );
};
