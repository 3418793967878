import React from "react";

import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

import "./GetStarted.css";

export const GetStarted = () => {
  return (
    <>
      <section className=" getstarted-section">
        <Container>
          <div className="started-with-danfe d-flex">
            <div className="img-wrap">
              <img src="images/image.png" alt="" />
            </div>

            <div className="content-wrapper">
              <div className="icon">
                <img src="images/icons.png" alt="" className="w-auto" />
              </div>
              <div className="content">
                <h6 className="title">
                  Get started on <strong>Danphe Traders as a Wholesaler /Retailer.</strong>
                </h6>
                <p>Join other shoppers in your area, and try Danphe today.</p>
                <Link to="/" className="cl-button">
                  Get Started Now
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};
