
import { axiosInstanceWithoutToken, storeToken } from "../Api";
export const getLogin = async (data) => {
  try {
    const loginResponse = await axiosInstanceWithoutToken.post(
      "/login?",
      null,
      {
        params: { email: data?.email, password: data?.password },
      }
    );

    const accessToken = loginResponse.data.access_token;
    storeToken(accessToken);
    return loginResponse.data;
  } catch (err) {
    console.log("login api fetch failed:", err);
  }
};
