import "./Pagination.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export const Pagination = ({
  itemsPerPage,
  data,
  currentPage,
  onPageChange,
  setCurrentPage,
}) => {
  if (!data || !data.length) {
    return null;
  }

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleNext = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handleInputChange = (event) => {
    setCurrentPage(event?.target?.value);
  };

  return (
    <>
      <nav aria-label="Page navigation example">
        <ul
          className="pagination custom-pagination justify-content-center"
          style={{ marginBottom: "96px" }}
        >
          <li style={{ display: "flex" }} className="page-item">
            {currentPage > 1 && (
              <button
                className="page-link "
                onClick={handlePrevious}
                aria-label="Previous"
                enbled={currentPage === 1}
                style={{ borderRadius: "none" }}
              >
                <IoIosArrowBack />
              </button>
            )}
            <span
              type="text"
              className={`page-link page ${currentPage === 1 ? "active" : ""}`}
              aria-label="Previous"
              // value={currentPage}
              // onChange={handleInputChange}
            >
              {currentPage}
            </span>
          </li>

          {Array.from({ length: 1 }, (_, index) => (
            <li
              className={`page-item ${
                currentPage === index + 1 ? "active" : ""
              }`}
              key={index}
            >
              <button
                className="page-link pagination"
                onClick={() => onPageChange(index + 1)}
                style={{  fontWeight: "600" }}
              >
                / {totalPages}
              </button>
            </li>
          ))}

          <li className="page-item">
            <button
              className="page-link"
              onClick={handleNext}
              aria-label="Next"
              // disabled={currentPage === totalPages}
            >
              <IoIosArrowForward
                className="icon"
               
              />
            </button>
          </li>
        </ul>
      </nav>
    </>
  );
};
