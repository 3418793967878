import React from "react";

import "./BreadCrumbs.css";
import { Container } from "react-bootstrap";

export const BreadCurmbs = ({ title, subtitle }) => {
  
  return (
    <>
      <div className="custom-breadcrumbs">
        <Container>
          <div className="cl-header">
            <span className="subtitle">{subtitle}</span>
            <h2 className="maintitle">{title}</h2>
          </div>
        </Container>
      </div>
    </>
  );
};
