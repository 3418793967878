import { axiosInstance } from "../Api";

export const getCategoryApi = async () => {
  try {
    const categoryApi = await axiosInstance.get("/category");
    return categoryApi.data;
  } catch (err) {
    console.log("error", err);
  }
};

export const getCategoryslug = async (slug) => {
  try {
    const categoryIdApi = await axiosInstance.get(
      `product-cat?category_slug=${slug}`
    );
    return categoryIdApi.data;
  } catch (err) {
    console.log("error", err);
  }
};
