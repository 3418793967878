import React, { useState } from "react";
import { toast } from "react-toastify";
import { RxCrossCircled } from "react-icons/rx";
import { Modal, Form, Button } from "react-bootstrap";
import { getForgetPasswordApi } from "../../Api/forgetpassword/ForgetPasswordApi";

const PopupForget = ({ onClick, popupData, setPopupData }) => {
  const [email, setEmail] = useState("");
  const [showPopup, setShowPopup] = useState(true);
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleClosePopup = () => {
    // setTimeout(() => {
    //   window.location.reload();
    setShowPopup(false);
    // }, 1000);
    onClick();
  };

  const isValidEmail = (email) => {
    return email.includes("@") && email.includes(".");
  };

  const handleClickForgetPassword = async (email) => {
    try {
      if (!isValidEmail(email)) {
        toast.error("The selected email is invalid.");
        return;
      }
      const updateForgetPassword = await getForgetPasswordApi(email);

      setEmail(updateForgetPassword.data);
      toast.success("Verification code was sent to your email address");
      handleClosePopup();
    } catch (err) {
      console.log("error", err);
      toast.error("The selected email is invalid.");
    }
  };

  const handleButtonClick = async () => {
    setButtonClicked(true);
    await handleClickForgetPassword(email);
    setButtonClicked(false);
  };

  return (
    <div>
      <div className="popup-container">
        <Modal
          show={showPopup}
          onHide={handleClosePopup}
          centered
          dialogClassName="modal-lg"
          className="testimonial-popup"
        >
          <Modal.Header closeButton={false}>
            <Modal.Title>Enter your Email</Modal.Title>
            <RxCrossCircled className="icon-popup" onClick={handleClosePopup} />
          </Modal.Header>

          <Modal.Body className="scrollable-modal-body">
            <Form className="popup-row">
              <Form.Control
                type="text"
                name="email"
                placeholder="Enter your email "
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form>

            <div className="forget-button">
              <Button
                className="forget-password-btn"
                onClick={() => handleButtonClick()}
                disabled={buttonClicked}
              >
                {buttonClicked ? "Sending..." : "Send"}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default PopupForget;
