import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./Components/Style/Main.css";

import "react-toastify/dist/ReactToastify.css";
import { Login } from "./Components/Auth/Login";
import { ToastContainer } from "react-toastify";
import PublicLayout from "./Layouts/PublicLayout";
import { Home } from "./Components/HomePage/Home";
import { Error } from "./Components/errorPage/Error";
import { Register } from "./Components/Auth/Register";
import { AllBrand } from "./Components/Brands/AllBrand";
import ProtectedLayout from "./Layouts/ProtectedLayout";
import GlobalContextProvider from "./Context/GlobalContext";
import { AllProduct } from "./Components/Product/AllProduct";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Profile } from "./Components/Auth/Profile/ProfileTabs";
import ScrollToTopOnNavigation from "./Components/ScrollWindow";
import SubmitEnquiry from "./Components/submitpage/SubmitEnquiry";
import { Contact } from "./Components/innerPage/Contact/Contact";
import { BadgeProvider } from "./Components/Product/BadageContext";
import { Productdetail } from "./Components/Product/ProductDetail";

import { BrandContextApi } from "./Components/Brands/BrandContextApi";
import { AboutPage } from "./Components/innerPage/AboutPage/AboutPage";
import { EnquiryList } from "./Components/innerPage/EnquiryList/EnquiryList";
import EnquiryHistory from "./Components/innerPage/EnquiryList/EnquiryHistory";
import { ContextApiCategory } from "./Components/Product/ContextApiCategory";
import ResetPasswordPage from "./Components/forgetpassword/ResetPasswordPage";

// import PopupForget from "./Components/Auth/PopupForget";

const App = () => {
  const token = localStorage.getItem("access_token");
  const navigate = useNavigate();

  if (token) {
    if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/register"
    ) {
      navigate("/");
    }
  }

 
  return (
    <ContextApiCategory>
      <BrandContextApi>
        <BadgeProvider>
          <GlobalContextProvider>
            <div className="App">
              <ScrollToTopOnNavigation />
              <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />

              <Routes>
                <Route element={<PublicLayout />}>
                  <Route path="/" element={<Home />} />
                  <Route path="/login" element={<Login />} />

                  <Route path="/register" element={<Register />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/submitpage" element={<SubmitEnquiry />} />
                  {/* <Route path="/errorpage" element={<Error />} /> */}

                  <Route path="/contact" element={<Contact />} />
                  <Route path="/allproduct" element={<AllProduct />} />
                  <Route path="/product-detail" element={<Productdetail />} />
                  <Route path="/allbrands" element={<AllBrand />} />
                  <Route path="/enquirylist" element={<EnquiryList />} />
                  <Route path="/enquiryhistory" element={<EnquiryHistory />} />
                  <Route
                    path="/forget-password/reset/:code"
                    element={<ResetPasswordPage />}
                  />
                  {/* <Route
                    path="/resetpasswordpage"
                    element={<ResetPasswordPage />}
                  /> */}
                </Route>

                <Route element={<ProtectedLayout />}>
                  <Route path="/profile" element={<Profile />} />
                </Route>
                <Route path="*" element={<Error />} />
              </Routes>
            </div>
          </GlobalContextProvider>
        </BadgeProvider>
      </BrandContextApi>
    </ContextApiCategory>
  );
};

export default App;
