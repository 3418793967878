import { createContext, useEffect, useState } from "react";
import {
  getCategoryApi,
  getCategoryslug,
} from "../../Api/categoryApi/CategoryApi";
import { useNavigate } from "react-router-dom";

const ApiContext = createContext();

const ContextApiCategory = ({ children }) => {
  const [category, setCategory] = useState("");
  const [selectCatergorySlug, setSelectCategorySlug] = useState("");
  const [activeCategory, setActiveCategory] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const getCategoriesApi = async () => {
      try {
        const updateCategory = await getCategoryApi();
        setCategory(updateCategory.data);
      } catch (err) {
        console.log("error", err);
      }
    };
    getCategoriesApi();
  }, []);

  const handleClickCategory = async (slug) => {
    try {
      const updateCategoryProduct = await getCategoryslug(slug);
      setSelectCategorySlug(updateCategoryProduct.data);
      navigate(`/allproduct/?category_slug=${slug}`);

      setActiveCategory(slug);
    
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (err) {
      console.log("error", err);
    }
  };
  return (
    <ApiContext.Provider
      value={{
        category,
        selectCatergorySlug,
        activeCategory,
        setActiveCategory,
        setSelectCategorySlug,
        handleClickCategory,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export { ContextApiCategory, ApiContext };
