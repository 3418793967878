import React, { useEffect, useState } from "react";

import { MoonLoader } from "react-spinners";

import { Pagination } from "../Pagination/Pagination";
import { Col, Container, Row } from "react-bootstrap";

import { BreadCurmbs } from "../Breadcrumbs/Breadcrumbs";
import { getBrandApi } from "../../Api/brandApi/BrandApi";

import { useBrandContext } from "./BrandContextApi";
import Loading from "../loader/Loading";

export const AllBrand = () => {
  const { getUpdateBrandId } = useBrandContext();

  const itemsPerPage = 12;
  const [brand, setBrand] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const displayedItems = brand.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    const getAllBrands = async () => {
      try {
        const updateAllBrands = await getBrandApi();
        console.log("updateAllBrands", updateAllBrands);
        setBrand(updateAllBrands.data);
        setLoading(false);
      } catch (err) {
        console.log("error", err);
        setLoading(false);
      }
    };
    getAllBrands();
  }, []);

  const handleClickBrandId = async (id) => {
    console.log("Clicked brand ID:", id);
    getUpdateBrandId(id);
  };

  return (
    <>
      <BreadCurmbs title="All Brands" subtitle="SHOP BY BRANDS" />
      <section className="section-padding allbrand-section">
        <Container>
          {loading || displayedItems?.length === 0 ? (
            <div className="loader-displayedItems">
              <Loading />
            </div>
          ) : (
            <Row>
              {Array.isArray(displayedItems) &&
                displayedItems?.map((data, index) => (
                  <Col md={3} xs={6}>
                    <div className="item" key={index}>
                      <div
                        className="brand-wrap"
                        onClick={() => handleClickBrandId(data.id)}
                      >
                        <div className="img-wrap">
                          <img src={data.photo} alt="brand1" />
                        </div>
                        <h6 className="title">{data.title}</h6>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          )}

          <Pagination
            itemsPerPage={itemsPerPage}
            data={brand}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            setCurrentPage={setCurrentPage}
          />
        </Container>
      </section>
    </>
  );
};
