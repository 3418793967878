import React, { useContext, useEffect, useRef, useState } from "react";

import {
  Col,
  Container,
  DropdownButton,
  Form,
  Row,
  Dropdown,
} from "react-bootstrap";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { BiSearch } from "react-icons/bi";
import { BreadCurmbs } from "../Breadcrumbs/Breadcrumbs";
import { getBrandApi, getBrandApiId } from "../../Api/brandApi/BrandApi";
import { getProductDetailApi } from "../../Api/productApi/ProductDetail";

import "./Product.css";

import {
  getProductApi,
  getProductSearch,
} from "../../Api/productApi/ProductApi";
import { getCategoryslug } from "../../Api/categoryApi/CategoryApi";

import {
  getProductByBrand,
  getProductByCategory,
  getProductByPrice,
  getProductByTitle,
} from "../../Api/filterApi/FilterApi";

import {
  getProductViewNine,
  getProductViewTwenty,
  getProductViewfifteen,
} from "../../Api/filterApi/FilterViewApi";

import { useBrandContext } from "../Brands/BrandContextApi";
import { ApiContext } from "./ContextApiCategory";
import Loading from "../loader/Loading";

export const AllProduct = () => {
  const {
    brandProductId,
    activeBrand,
    getUpdateBrandId,
    setBrandProductId,
    noProductMessage,
  } = useBrandContext();
  console.log("brandProductId;;;", brandProductId);
  const {
    category,
    activeCategory,
    handleClickCategory,
    selectCatergorySlug,
    setSelectCategorySlug,
    setActiveCategory,
  } = useContext(ApiContext);
  const inputRef = useRef(null);
  const [brand, setBrand] = useState("");
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [itemDetail, setItemDetail] = useState([]);
  const [allProduct, setAllProduct] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productBrand, setProductBrand] = useState("");
  const [productTitle, setProductTitle] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectViewNine, setSelectViewNine] = useState("");
  const [selectedBrands, setSelectedBrands] = useState([]);
  console.log("selectedBrands", selectedBrands);
  const [productCategory, setProductCategory] = useState("");
  const [selectViewTwenty, setSelectViewTwenty] = useState("");
  const [selectViewFifteen, setSelectViewFifteen] = useState("");
  const [selectedViewOption, setSelectedViewOption] = useState("");
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [viewAllBrand, setViewAllBrand] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const locationState = location.state;
  const { products } = locationState || {};

  //  const Input = styled.input`
  //    ${({ theme }) => css`
  //      border: 1px solid white;

  //      &:checked {
  //        background-color: green;
  //        border-color: green;
  //      }
  //    `}
  //  `;

  useEffect(() => {
    const getCategoriesApi = async () => {
      try {
        const updateBrand = await getBrandApi();
        setBrand(updateBrand.data);
      } catch (err) {
        console.log("error", err);
      }
    };
    getCategoriesApi();
  }, []);

  // const handleUpdateBrandId = async (id) => {
  //   getUpdateBrandId(id);
  // };

  // const handleUpdateBrandId = async (id) => {
  //   if (Array.isArray(id)) {
  //     await getUpdateBrandId(id);
  //   } else {
  //     await getUpdateBrandId([id]);
  //   }

  // };

  const handleUpdateBrandId = async (brandId) => {
    let updatedSelectedBrands;
    if (selectedBrands.includes(brandId)) {
      updatedSelectedBrands = selectedBrands.filter((id) => id !== brandId);
    } else {
      updatedSelectedBrands = [...selectedBrands, brandId];
    }
    setSelectedBrands(updatedSelectedBrands);
    await getUpdateBrandId(updatedSelectedBrands);
  };

  const handleToggleBrand = async (brandId) => {
    let updatedSelectedBrands;
    if (selectedBrands.includes(brandId)) {
      updatedSelectedBrands = selectedBrands.filter((id) => id !== brandId);
    } else {
      updatedSelectedBrands = [...selectedBrands, brandId];
    }

    setSelectedBrands(updatedSelectedBrands);

    await getUpdateBrandId(updatedSelectedBrands);
  };

  const handleBackButton = () => {
    if (selectedBrands.length > 0) {
      const updatedSelectedBrands = [];

      setSelectedBrands(updatedSelectedBrands);

      const updatedProducts = brandProductId.filter((product) =>
        updatedSelectedBrands.includes(product.id)
      );

      setBrandProductId(updatedProducts);
    }
    const url = new URL(window.location.href);
    url.searchParams.delete("brand_id");
    window.history.pushState({}, "", url);

    setSelectedBrands([]);
    setTimeout(() => {
      setSelectedBrands([]);

      window.location.reload();
    }, 100);
  };

  useEffect(() => {
    window.onpopstate = handleBackButton;

    return () => {
      window.onpopstate = null;
    };
  }, [selectedBrands]);

  const handleClickAll = async () => {
    const getAllProduct = await getProductApi();
    setAllProduct(getAllProduct);
    navigate("/allproduct");
    setActiveCategory("all");
  };

  useEffect(() => {
    const getsearchProduct = async () => {
      try {
        const updateSearch = await getProductSearch(searchQuery);
        setProduct(updateSearch.data);
      } catch (err) {
        console.log("error", err);
      }
    };
    getsearchProduct();
  }, [searchQuery]);

  const handleClickSearch = async () => {
    try {
      const updateSearch = await getProductSearch(searchQuery);
      setProduct(updateSearch.data);
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    const categorySearchUrl = new URLSearchParams(location.search).get(
      "category_slug"
    );
    const brandSearchUrl = new URLSearchParams(location.search).get("brand_id");
    const SearchUrl = new URLSearchParams(location.search).get("sort_by");
    const SearchViewUrl = new URLSearchParams(location.search).get("per_page");

    const fetchProducts = async () => {
      try {
        if (categorySearchUrl) {
          const updateCategoryProduct = await getCategoryslug(
            categorySearchUrl
          );
          setSelectCategorySlug(updateCategoryProduct.data);
        } else {
          const updateProduct = await getProductApi();
          setSelectCategorySlug("");
          setProduct(updateProduct.data);
        }
      } catch (err) {
        console.log("error", err);
        if (categorySearchUrl && err.message !== categorySearchUrl.slug) {
          navigate("/errorpage");
        }
        return;
      }

      try {
        if (SearchUrl) {
          const updateTitleProduct = await getProductByTitle(SearchUrl);
          console.log("updateCategoryProduct", updateTitleProduct);
          setProductTitle(updateTitleProduct.data);
        } else {
          const updateProductTitle = await getProductApi();
          console.log("updateProduct", updateProductTitle);
          setProductTitle("") ||
            setProductPrice("") ||
            setProductCategory("") ||
            setProductBrand("");
          setProduct(updateProductTitle.data);
        }
      } catch (err) {
        console.log("error", err);
      }

      try {
        if (SearchViewUrl) {
          const updateSearchViewUrl = await getProductViewNine(SearchViewUrl);
          setProductTitle(updateSearchViewUrl.data);
        } else {
          const updateProductView = await getProductApi();
          setSelectViewNine("") ||
            setSelectViewFifteen("") ||
            setSelectViewTwenty("") ||
            setProduct(updateProductView.data);
        }
      } catch (err) {
        console.log("error", err);
      }

      try {
        if (brandSearchUrl) {
          const updatebrandUrl = await getBrandApiId(brandSearchUrl);
          setProductTitle(updatebrandUrl.data);
        } else {
          const updateProductBrandId = await getProductApi();
          setBrandProductId("");
          setProduct(updateProductBrandId.data);
        }
      } catch (err) {
        console.log("error", err);
      }
    };

    fetchProducts();
  }, [location.search]);

  const filterProduct = product.filter((data) => {
    if (selectCatergorySlug) {
      return data.category === selectCatergorySlug.slug;
    }

    if (brandProductId.length > 0) {
      return data.brandId === brandProductId.id;
    }
    return true;
  });

  const productsPerPage = 12;

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const productDisplay = filterProduct.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  if (productDisplay.length === 0) {
    <Loading />;
  }
  console.log("productDisplay;;;", productDisplay);

  // Calculate the index of the first and last product to display on the current page

  // const product = productinfo.slice(
  //   indexOfFirstProduct,
  //   indexOfLastProduct
  // );
  const totalPages = Math.ceil(product.length / productsPerPage);
  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleClick = async (slug) => {
    try {
      const updateDetails = await getProductDetailApi(slug);
      navigate(`/product-detail?slug=${slug}`, {
        state: { productData: updateDetails, slug: slug },
      });
      setItemDetail(slug);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (err) {
      console.log(err);
    }
  };

  // const handleClickTitle = async (event) => {
  //   const selectedValue = event?.target?.value;
  //   console.log("selectedValue;;", selectedValue);
  //   if (selectedValue === "Name") {
  //     try {
  //       const title = "title";
  //       const updatedTitle = await getProductByTitle(title);
  //       console.log("updatedTitle:", updatedTitle);
  //       setProductTitle(updatedTitle.data);
  //       navigate(`/allproduct/?sort_by=${title}`);
  //     } catch (err) {
  //       console.error("error", err);
  //     }
  //   }

  //   if (selectedValue === "Price") {
  //     try {
  //       const price = "price";
  //       const updatedPrice = await getProductByPrice(price);
  //       console.log("updatedPrice:", updatedPrice);
  //       setProductPrice(updatedPrice.data);
  //       navigate(`/allproduct/?sort_by=${price}`);
  //     } catch (err) {
  //       console.error("error", err);
  //     }
  //   }

  //   if (selectedValue === "Category") {
  //     try {
  //       const categories = "cat_id";
  //       const updatedCategory = await getProductByCategory(categories);
  //       console.log("updatedTitle:", updatedCategory);
  //       setproductCategory(updatedCategory.data);
  //       navigate(`/allproduct/?sort_by=${categories}`);
  //     } catch (err) {
  //       console.error("error", err);
  //     }
  //   }

  //   if (selectedValue === "Brand") {
  //     try {
  //       const brand = "brand_id";
  //       const updatedProductBrand = await getProductByBrand(brand);
  //       console.log("updatedProductBrand:", updatedProductBrand);
  //       setProductBrand(updatedProductBrand.data);
  //       navigate(`/allproduct/?sort_by=${brand}`);
  //     } catch (err) {
  //       console.error("error", err);
  //     }
  //   }

  //   if (selectedValue === "Default Sorting") {
  //     try {
  //       navigate(`/allproduct`);
  //     } catch (err) {
  //       console.error("error", err);
  //     }
  //   }

  //   if (selectedValue === "Sorting") {
  //     try {
  //       navigate(`/allproduct`);
  //     } catch (err) {
  //       console.error("error", err);
  //     }
  //   }
  // };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sortParam = params.get("sort_by");

    if (location.pathname === "/allproduct") {
      setSelectedOption("Default");
    } else if (sortParam === "title") {
      setSelectedOption("Name");
    } else if (sortParam === "price") {
      setSelectedOption("Price");
    } else if (sortParam === "cat_id") {
      setSelectedOption("Category");
    } else if (sortParam === "brand_id") {
      setSelectedOption("Brand");
    } else {
      setSelectedOption("");
    }
  }, [location.pathname, location.search]);

  // const scrollHandle = () => {
  //   window.scrollTo({
  //     top: document.body.scrollHeight / 2,
  //     behavior: "smooth",
  //   });
  // };

  const handleClickProductView = async (event) => {
    // scrollHandle();
    const selectedView = event.target.value;
    if (selectedView === "09") {
      try {
        const view9 = "9";
        const updatedViewNine = await getProductViewNine(view9);
        console.log("updatedViewNine", updatedViewNine);
        setSelectViewNine(updatedViewNine.data);
        navigate(`/allproduct/?per_page=${view9}`);
      } catch (err) {
        console.log("error", err);
      }
    }

    if (selectedView === "15") {
      try {
        const view15 = "15";
        const updateViewfifteen = await getProductViewfifteen(view15);
        console.log("updateViewfifteen;;;", updateViewfifteen);
        setSelectViewFifteen(updateViewfifteen.data);
        navigate(`/allproduct/?per_page=${view15}`);
      } catch (err) {
        console.log("error", err);
      }
    }

    if (selectedView === "20") {
      try {
        const view20 = "20";
        const updateViewtwenty = await getProductViewTwenty(view20);
        console.log("updateViewtwenty;;;", updateViewtwenty);
        setSelectViewTwenty(updateViewtwenty.data);
        navigate(`/allproduct/?per_page=${view20}`);
      } catch (err) {
        console.log("error", err);
      }
    }
    if (selectedView === "Default") {
      try {
        navigate(`/allproduct`);
      } catch (err) {
        console.error("error", err);
      }
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const viewParam = params.get("per_page");

    if (location.pathname === "/allproduct") {
      setSelectedViewOption("Default");
    } else if (viewParam === "9") {
      setSelectedViewOption("09");
    } else if (viewParam === "15") {
      setSelectedViewOption("15");
    } else if (viewParam === "20") {
      setSelectedViewOption("20");
    } else {
      setSelectedViewOption("");
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    const getApiProduct = async () => {
      try {
        const updateGetAllProduct = await getProductApi();
        console.log("updateGetAllProduct", updateGetAllProduct);
        setProduct(updateGetAllProduct.data);
        setLoading(false);
      } catch (err) {
        console.log("error", err);
      }
    };
    getApiProduct();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setSearchQuery(""); // Clear the search query
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //const isBrandPath = location.pathname.includes(`/allproduct/?brand_id=${id}`);
  return (
    <>
      <BreadCurmbs title="All Products" subtitle="Shop" />
      <section className="section-padding allproduct-section">
        <Container>
          <Row className="product-row">
            <Col lg={3} md={4}>
              <div className="left-wrapper">
                <div className="d-md-none dropdown-category-brand">
                  <DropdownButton
                    id="categoryDropdown"
                    title="Product Categories"
                    variant="light"
                    drop="down"
                  >
                    <Dropdown.Item
                      onClick={handleClickAll}
                      active={activeCategory === "all"}
                    >
                      All
                    </Dropdown.Item>
                    {Array.isArray(category) &&
                      category.map((item, index) =>
                        index < 3 || showAllCategories ? (
                          <Dropdown.Item
                            key={index}
                            onClick={() => handleClickCategory(item.slug)}
                            active={activeCategory === item.slug}
                          >
                            {item.title}
                          </Dropdown.Item>
                        ) : null
                      )}
                    {category && category.length > 3 && (
                      <span
                        onClick={() => setShowAllCategories(!showAllCategories)}
                        style={{
                          cursor: "pointer",
                          color: "#1A68B2",
                          fontWeight: "bold",
                          marginTop: "10px",
                          marginLeft: "10px",
                          fontSize: "14px",
                        }}
                      >
                        {showAllCategories ? "- View Less" : "+ View More"}
                      </span>
                    )}
                  </DropdownButton>

                  <DropdownButton
                    id="brandDropdown"
                    title="Filter by Brand"
                    variant="light"
                    drop="down"
                  >
                    {Array.isArray(brand) &&
                      brand.map((items, index) =>
                        index < 5 || viewAllBrand ? (
                          <div className="checkbox-brand-dropdown">
                            <input
                              key={index}
                              type="checkbox"
                              id={`brand-checkbox-${items.id}`}
                              // label={items.title}
                              checked={selectedBrands.includes(items.id)}
                              onChange={() => handleToggleBrand(items.id)}
                              style={{
                                cursor: "pointer",
                                backgroundColor: selectedBrands.includes(
                                  items.id
                                )
                                  ? "red"
                                  : "inherit",
                              }}
                            />
                            <Dropdown.Item
                              key={index}
                              onClick={() => handleUpdateBrandId(items.id)}
                              active={activeBrand === items.id}
                            >
                              {items.title}
                            </Dropdown.Item>
                          </div>
                        ) : null
                      )}
                    {brand.length > 3 && (
                      <span
                        onClick={() => setViewAllBrand(!viewAllBrand)}
                        style={{
                          cursor: "pointer",
                          color: "#1A68B2",
                          fontWeight: "bold",
                          marginTop: "10px",
                          marginLeft: "10px",
                          fontSize: "14px",
                        }}
                      >
                        {viewAllBrand ? "- View Less" : "+ View More"}
                      </span>
                    )}
                  </DropdownButton>
                </div>

                <div className="d-none d-md-block">
                  <div className="box">
                    <h4 className="title">Product Categories</h4>

                    {Array.isArray(category) ? (
                      <>
                        <ul>
                          <li
                            onClick={handleClickAll}
                            style={{
                              color: activeCategory === "all" ? "#1A68B2" : "",
                            }}
                          >
                            All
                          </li>

                          {category?.map((item, index) =>
                            index < 4 || showAllCategories ? ( // Conditionally render only the first three categories initially or if showAllCategories is true
                              <li
                                key={index}
                                onClick={() => handleClickCategory(item.slug)}
                                style={{
                                  color:
                                    activeCategory === item.slug
                                      ? "#1A68B2"
                                      : "",
                                }}
                              >
                                {item.title}
                              </li>
                            ) : null
                          )}
                        </ul>

                        {category && category.length > 3 && (
                          <span
                            onClick={() =>
                              setShowAllCategories(!showAllCategories)
                            }
                            style={{
                              cursor: "pointer",
                              color: "#1A68B2",
                              fontWeight: "bold",
                              lineHeight: "3",
                              fontSize: "15px",
                            }}
                          >
                            {showAllCategories ? "- View Less" : "+ View More"}
                          </span>
                        )}
                      </>
                    ) : (
                      <p>Loading..</p>
                    )}
                  </div>
                  <div className="box">
                    <h4 className="title">Filter by Brand</h4>
                    <>
                      {Array.isArray(brand) ? (
                        <>
                          <ul>
                            {brand?.map((items, index) =>
                              index < 5 || viewAllBrand ? (
                                <div
                                  className="checkbox-brand"
                                  key={index}
                                  style={{ lineHeight: "28px" }}
                                >
                                  <input
                                    type="checkbox"
                                    id={`brand-checkbox-${items.id}`}
                                    checked={selectedBrands.includes(items.id)}
                                    onChange={() => handleToggleBrand(items.id)}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <li
                                    onClick={() =>
                                      handleUpdateBrandId(items.id)
                                    }
                                    style={{
                                      color:
                                        activeBrand === items.id
                                          ? "#1A68B2"
                                          : "",
                                    }}
                                  >
                                    {items.title}
                                  </li>
                                </div>
                              ) : null
                            )}
                          </ul>
                          {brand.length > 5 && (
                            <span
                              onClick={() => setViewAllBrand(!viewAllBrand)}
                              style={{
                                cursor: "pointer",
                                color: "#1A68B2",
                                fontWeight: "bold",
                                lineHeight: "3",
                                fontSize: "15px",
                              }}
                            >
                              {viewAllBrand ? "- View Less" : "+ View More"}
                            </span>
                          )}
                        </>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </>
                  </div>

                  {/* <div
                    className="bg-product"
                    style={{
                      background: `url(../../images/puff.png)`,
                      backgroundSize: "cover",
                      backgroundPosition: "Center",
                      marginBottom: "96px",
                      width: "234px",
                      height: "234px",
                      marginLeft:"-15px"
                    }}
                  >
                    <h2 className="title" style={{ fontSize: "20px" }}>
                      Fast Fashion Product
                    </h2>
                    <Link to="">
                      Enquire Now
                      <IoIosArrowForward />
                    </Link>
                  </div> */}
                </div>
              </div>
            </Col>

            <Col lg={9} md={8}>
              <div className="right-wrapper">
                <div className="search-bar top-product-header d-flex align-items-center">
                  <div className="form-group d-flex" ref={inputRef}>
                    <input
                      type="text"
                      className="form-control-product"
                      placeholder="Search "
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      style={{ outline: "none" }}
                    />
                    <div
                      className="icon-wrap"
                      style={{
                        backgroundColor: "#1A68B2",
                        borderBottomRightRadius: "5px",
                        borderTopRightRadius: "5px",
                      }}
                    >
                      <BiSearch
                        className="icons"
                        onClick={handleClickSearch}
                        style={{ color: "white" }}
                      />
                    </div>
                  </div>
                  <div
                    className="sorting-wrap d-flex justify-content-center"
                    style={{ display: "flex", gap: "7px" }}
                  >
                    {/* <strong>Sort:</strong> */}

                    {/* <Form.Select
                      className="form-control"
                      onChange={handleClickTitle}
                      value={selectedOption}
                    >
                      {windowWidth < 400 ? (
                        <option>Sorting</option>
                      ) : (
                        <option>Default Sorting</option>
                      )}

                      <option>Name</option>
                      <option>Price</option>
                      <option>Category</option>
                      <option>Brand</option>
                    </Form.Select> */}

                    <strong style={{ color: "black" }} className="text-name">
                      View:
                    </strong>
                    <Form.Select
                      className="form-control-view"
                      onChange={handleClickProductView}
                      value={selectedViewOption}
                      style={{ cursor: "pointer" }}
                    >
                      <option style={{ color: "#929292" }}>Default</option>
                      <option>09</option>
                      <option>15</option>
                      <option>20</option>
                    </Form.Select>

                    {/* <div className="icon">
                      <IoAppsSharp />
                    </div> */}
                  </div>
                </div>
                {loading || productDisplay?.length === 0 ? (
                  <div className="loader-products">
                    <Loading />
                  </div>
                ) : (
                  <div className="product-array">
                    <Row>
                      {console.log(";;;;;;;;;;", productDisplay)}
                      {Array.isArray(productDisplay) &&
                      !selectCatergorySlug &&
                      !brandProductId &&
                      // !productTitle &&
                      // !productCategory &&
                      // !productPrice &&
                      // !productBrand &&
                      !selectViewNine &&
                      !selectViewFifteen &&
                      !products &&
                      !selectViewTwenty ? (
                        productDisplay?.map((data, index) => (
                          <Col className="p-0" key={index} lg={3} md={4} xs={6}>
                            <div className="product-wrap">
                              <div onClick={() => handleClick(data.slug)}>
                                <div className="img-product">
                                  <img src={data?.photo} alt="" />
                                </div>
                                <h6 className="title">{data?.title}</h6>
                                {/* <p className="title">Rs:{data.price}</p> */}

                                <Link to="" className="morebtn">
                                  Enquire
                                </Link>
                              </div>
                            </div>
                          </Col>
                        ))
                      ) : Array.isArray(productDisplay) &&
                        selectCatergorySlug &&
                        // !productTitle &&
                        // !productCategory &&
                        // !productPrice &&
                        // !productBrand &&
                        !selectViewNine &&
                        !selectViewFifteen &&
                        !products &&
                        !selectViewTwenty ? (
                        <React.Fragment>
                          {selectCatergorySlug[0].products?.length === 0 ? (
                            <h1 className="no-more-product">
                              No more products available
                            </h1>
                          ) : (
                            <React.Fragment>
                              {selectCatergorySlug[0].products?.map(
                                (data, index) => (
                                  <Col
                                    lg={3}
                                    md={4}
                                    xs={6}
                                    className="p-0"
                                    key={index}
                                  >
                                    <div className="product-wrap">
                                      <div
                                        onClick={() => handleClick(data.slug)}
                                      >
                                        <div className="img-product">
                                          <img src={data?.photo} alt="" />
                                        </div>
                                        <h6 className="title">{data?.title}</h6>
                                        <Link to="" className="morebtn">
                                          Enquire
                                        </Link>
                                      </div>
                                    </div>
                                  </Col>
                                )
                              )}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : Array.isArray(productDisplay) &&
                        brandProductId &&
                        // !productTitle &&
                        // !productCategory &&
                        // !productPrice &&
                        // !productBrand &&

                        !selectViewNine &&
                        !selectViewFifteen &&
                        !products &&
                        !selectViewTwenty ? (
                        noProductMessage ? (
                          <h1 className="no-more-product">
                            {noProductMessage}
                          </h1>
                        ) : (
                          brandProductId.map((data, index) => (
                            <Col
                              lg={3}
                              md={4}
                              xs={6}
                              className="p-0"
                              key={index}
                            >
                              <div className="product-wrap">
                                <div onClick={() => handleClick(data.slug)}>
                                  <div className="img-product">
                                    <img src={data?.photo} alt="" />
                                  </div>
                                  <h6 className="title">{data?.title}</h6>
                                  {/* <p className="title">Rs:{data.price}</p> */}

                                  <Link to="" className="morebtn">
                                    Enquire
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          ))
                        )
                      ) : Array.isArray(productTitle) &&
                        // productTitle &&
                        // !productCategory &&
                        // !productPrice &&
                        // !productBrand &&
                        !selectViewNine &&
                        !selectViewFifteen &&
                        !products &&
                        !selectViewTwenty ? (
                        <React.Fragment>
                          {console.log("productTitle", productTitle)}
                          {productTitle?.map((data, index) => (
                            <Col
                              lg={3}
                              md={4}
                              xs={6}
                              className="p-0"
                              key={index}
                            >
                              <div className="product-wrap">
                                <div onClick={() => handleClick(data.slug)}>
                                  <div className="img-product">
                                    <img src={data?.photo} alt="" />
                                  </div>
                                  <h6 className="title">{data?.title}</h6>
                                  {/* <p className="title">Rs:{data.price}</p> */}

                                  <Link to="" className="morebtn">
                                    Enquire
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </React.Fragment>
                      ) : //: Array.isArray(productDisplay) &&
                      //   // productTitle &&
                      //   // !productCategory &&
                      //   // !productPrice &&
                      //   // !productBrand &&
                      //   !selectViewNine &&
                      //   !selectViewFifteen &&
                      //   !products &&
                      //   !selectViewTwenty ? (
                      //   <React.Fragment>
                      //     {console.log("  productTitle", productTitle)}
                      //     {productTitle?.map((data, index) => (
                      //       <Col
                      //         lg={3}
                      //         md={4}
                      //         sm={6}
                      //         className="p-0"
                      //         key={index}
                      //       >
                      //         <div className="product-wrap">
                      //           <div onClick={() => handleClick(data.slug)}>
                      //             <div className="img-product">
                      //               <img src={data.photo} alt="" />
                      //             </div>
                      //             <h6 className="title">{data.title}</h6>
                      //             {/* <p className="title">Rs:{data.price}</p> */}

                      //             <Link to="" className="morebtn">
                      //               Enquire
                      //             </Link>
                      //           </div>
                      //         </div>
                      //       </Col>
                      //     ))}
                      //   </React.Fragment>
                      // )
                      // : Array.isArray(productDisplay) &&
                      //   productCategory &&
                      //   !productPrice &&
                      //   !productBrand &&
                      //   !selectViewNine &&
                      //   !selectViewFifteen &&
                      //   !products &&
                      //   !selectViewTwenty ? (
                      //   <React.Fragment>
                      //     {console.log("productCategory", productCategory)}
                      //     {productCategory?.map((data, index) => (
                      //       <Col
                      //         lg={3}
                      //         md={4}
                      //         sm={6}
                      //         className="p-0"
                      //         key={index}
                      //       >
                      //         <div className="product-wrap">
                      //           <div onClick={() => handleClick(data.slug)}>
                      //             <div className="img-product">
                      //               <img src={data.photo} alt="" />
                      //             </div>
                      //             <h6 className="title">{data.title}</h6>
                      //             {/* <p className="title">Rs:{data.price}</p> */}

                      //             <Link to="" className="morebtn">
                      //               Enquire
                      //             </Link>
                      //           </div>
                      //         </div>
                      //       </Col>
                      //     ))}
                      //   </React.Fragment>
                      // ) : Array.isArray(productDisplay) &&
                      //   productPrice &&
                      //   !productBrand &&
                      //   !selectViewNine &&
                      //   !selectViewFifteen &&
                      //   !products &&
                      //   !selectViewTwenty ? (
                      //   <React.Fragment>
                      //     {console.log("productPrice ", productPrice)}
                      //     {productPrice?.map((data, index) => (
                      //       <Col
                      //         lg={3}
                      //         md={4}
                      //         sm={6}
                      //         className="p-0"
                      //         key={index}
                      //       >
                      //         <div className="product-wrap">
                      //           <div onClick={() => handleClick(data.slug)}>
                      //             <div className="img-product">
                      //               <img src={data.photo} alt="" />
                      //             </div>
                      //             <h6 className="title">{data.title}</h6>
                      //             {/* <p className="title">Rs:{data.price}</p> */}

                      //             <Link to="" className="morebtn">
                      //               Enquire
                      //             </Link>
                      //           </div>
                      //         </div>
                      //       </Col>
                      //     ))}
                      //   </React.Fragment>
                      // ) : Array.isArray(productDisplay) &&
                      //   productBrand &&
                      //   !selectViewNine &&
                      //   !selectViewFifteen &&
                      //   !products &&
                      //   !selectViewTwenty ? (
                      //   <React.Fragment>
                      //     {console.log("productBrand ", productBrand)}
                      //     {productBrand?.map((data, index) => (
                      //       <Col
                      //         lg={3}
                      //         md={4}
                      //         sm={6}
                      //         className="p-0"
                      //         key={index}
                      //       >
                      //         <div className="product-wrap">
                      //           <div onClick={() => handleClick(data.slug)}>
                      //             <div className="img-product">
                      //               <img src={data.photo} alt="" />
                      //             </div>
                      //             <h6 className="title">{data.title}</h6>
                      //             {/* <p className="title">Rs:{data.price}</p> */}

                      //             <Link to="" className="morebtn">
                      //               Enquire
                      //             </Link>
                      //           </div>
                      //         </div>
                      //       </Col>
                      //     ))}
                      //   </React.Fragment>
                      // )
                      Array.isArray(productDisplay) &&
                        selectViewNine &&
                        !selectViewFifteen &&
                        !products &&
                        !selectViewTwenty ? (
                        <React.Fragment>
                          {console.log("selectViewNine ", selectViewNine)}
                          {selectViewNine?.map((data, index) => (
                            <Col
                              lg={3}
                              md={4}
                              xs={6}
                              className="p-0"
                              key={index}
                            >
                              <div className="product-wrap">
                                <div onClick={() => handleClick(data.slug)}>
                                  <div className="img-product">
                                    <img src={data?.photo} alt="" />
                                  </div>
                                  <h6 className="title">{data?.title}</h6>
                                  {/* <p className="title">Rs:{data.price}</p> */}

                                  <Link to="" className="morebtn">
                                    Enquire
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </React.Fragment>
                      ) : Array.isArray(productDisplay) &&
                        selectViewFifteen &&
                        !products &&
                        !selectViewTwenty ? (
                        <React.Fragment>
                          {console.log("selectViewFifteen", selectViewFifteen)}
                          {selectViewFifteen?.map((data, index) => (
                            <Col
                              lg={3}
                              md={4}
                              sm={6}
                              className="p-0"
                              key={index}
                            >
                              <div className="product-wrap">
                                <div onClick={() => handleClick(data.slug)}>
                                  <div className="img-product">
                                    <img src={data?.photo} alt="" />
                                  </div>
                                  <h6 className="title">{data?.title}</h6>
                                  {/* <p className="title">Rs:{data.price}</p> */}

                                  <Link to="" className="morebtn">
                                    Enquire
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </React.Fragment>
                      ) : Array.isArray(productDisplay) &&
                        selectViewTwenty &&
                        !products ? (
                        <React.Fragment>
                          {console.log("selectViewTwenty ", selectViewTwenty)}
                          {selectViewTwenty?.map((data, index) => (
                            <Col
                              lg={3}
                              md={4}
                              xs={6}
                              className="p-0"
                              key={index}
                            >
                              <div className="product-wrap">
                                <div onClick={() => handleClick(data.slug)}>
                                  <div className="img-product">
                                    <img src={data?.photo} alt="" />
                                  </div>
                                  <h6 className="title">{data?.title}</h6>
                                  {/* <p className="title">Rs:{data.price}</p> */}

                                  <Link to="" className="morebtn">
                                    Enquire
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </React.Fragment>
                      ) : Array.isArray(productDisplay) && products ? (
                        <React.Fragment>
                          {console.log(" products ", products)}
                          {products?.map((data, index) => (
                            <Col
                              lg={3}
                              md={4}
                              xs={6}
                              className="p-0"
                              key={index}
                            >
                              <div className="product-wrap">
                                <div onClick={() => handleClick(data.slug)}>
                                  <div className="img-product">
                                    <img src={data?.photo} alt="" />
                                  </div>
                                  <h6 className="title">{data.title}</h6>
                                  {/* <p className="title">Rs:{data.price}</p> */}

                                  <Link to="" className="morebtn">
                                    Enquire
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </React.Fragment>
                      ) : (
                        <p>Loading..</p>
                      )}
                    </Row>
                  </div>
                )}

                <React.Fragment>
                  <nav aria-label="Page navigation example">
                    <ul
                      className="pagination custom-pagination justify-content-center "
                      style={{ marginBottom: "96px" }}
                    >
                      <li
                        style={{ display: "flex" }}
                        className={`page-item ${
                          currentPage === 1 ? "enable" : ""
                        }`}
                      >
                        {currentPage > 1 && (
                          <Link
                            className="page-link"
                            to={`/allproduct?page=${currentPage - 1}`}
                            aria-label="Previous"
                            onClick={() => paginate(currentPage - 1)}
                          >
                            <IoIosArrowBack className="icon" />
                          </Link>
                        )}
                        <span
                          type="text"
                          className={`page-link page ${
                            currentPage === 1 ? "active" : ""
                          }`}
                          aria-label="Previous"
                          // value={currentPage}
                          // onChange={handleInputChange}
                        >
                          {currentPage}
                        </span>
                      </li>

                      {Array.from({ length: 1 }, (_, index) => (
                        <li
                          className={`page-item ${
                            index + 1 === currentPage ? "active" : ""
                          }`}
                          key={index}
                        >
                          <Link
                            className="page-link pagination"
                            to={`/allproduct?page=${index + 1}`}
                            onClick={() => paginate(index + 1)}
                            style={{ fontWeight: "600" }}
                          >
                            / {totalPages}
                          </Link>
                        </li>
                      ))}

                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <Link
                          className="page-link"
                          to={`/allproduct?page=${currentPage + 1}`}
                          aria-label="Next"
                          onClick={() => paginate(currentPage + 1)}
                        >
                          <IoIosArrowForward className="icon" />
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </React.Fragment>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
