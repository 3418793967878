import { createContext, useState } from "react";

export const GlobalContext = createContext({
  profile: null,
  setProfile: () => null,
});

const GlobalContextProvider = ({ children }) => {
  
  const [profile, setProfile] = useState(null);

  return (
    <GlobalContext.Provider
      value={{
        profile,
        setProfile,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
