import { axiosInstance } from "../Api";

export const getEditProfile = async (newProfileData) => {
  try {
    const editProfileResponse = await axiosInstance.post(
      "/edit-profile",
      newProfileData
    );
    return editProfileResponse.data;
  } catch (err) {
    console.log("editprofile fetch failed:", err);
  }
};
