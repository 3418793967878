import React from "react";

import { Whyus } from "./Whyus/Whyus";
import { About } from "./About/About";

import { Brand } from "../Brands/Brand";
import { Banner } from "./Banner/Banner";
import { Client } from "./Clients/Client";

import { Product } from "../Product/Product";
import { GetStarted } from "./getstarted/GetStarted";
import { ShopSection } from "./ShopSection/ShopSection";
import { Testimonial } from "./Testimonials/Testimonial";

export const Home = () => {

  return (
    <>
      <Banner />
      <Client />
      <About />
      <Brand />
      <ShopSection />
      <Product />
      <Whyus />
      <Testimonial />
      <GetStarted />
    </>
  );
};
