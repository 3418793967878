import { axiosInstance } from "../Api";

export const getBrandApi = async () => {
  try {
    const BrandApi = await axiosInstance.get("/brands");
    return BrandApi.data;
  } catch (err) {
    console.log("error", err);
  }
};

// export const getBrandApiId = async (id) => {
//   try {
//     const brandIdsParam = id.join(",");
//     const updateBrandApiId = await axiosInstance.post(
//       `/product/filter?brand_id=${brandIdsParam}`
//     );
//     console.log("updateBrandApiId////", updateBrandApiId);
//     return updateBrandApiId.data;
//   } catch (err) {
//     console.log("error", err);
//   }
// };

export const getBrandApiId = async (id) => {
  try {
    const requestData = Array.isArray(id)
      ? { brand_id: id }
      : { brand_id: [id] };
    const responseData = await axiosInstance.post(
      "/product/filter",
      requestData
    );
    return responseData.data;
  } catch (err) {
    console.log("error", err);
    throw err;
  }
};
