import { createContext, useContext, useState } from "react";

const BadgeContext = createContext();

export const BadgeProvider = ({ children }) => {
  const [badgeCount, setBadgeCount] = useState(0);

  const [productListData, setProductListData] = useState([]);

  const incrementBadge = () => {
    const isLogged = !!localStorage.getItem("access_token");
    // setBadgeCount((prevCount) => prevCount + 1);
    if (isLogged) {
      setBadgeCount((prevCount) => prevCount + 1);
    }
  };

  const decrementBadge = () => {
    setBadgeCount((prevCount) => prevCount - 1);
  };

  const deleteProduct = (productId) => {
    setProductListData((prevList) => {
      const updateList = prevList.filter((product) => product.id !== productId);

      if (prevList.length !== updateList.length) {
        decrementBadge();
      }

      return updateList;
    });
  };

  const resetBadgeCount = () => {
    setBadgeCount(0);
  };

  return (
    <BadgeContext.Provider
      value={{
        badgeCount,
        setBadgeCount,
        incrementBadge,
        decrementBadge,
        productListData,
        setProductListData,
        deleteProduct,
        resetBadgeCount,
      }}
    >
      {children}
    </BadgeContext.Provider>
  );
};

export const useBadge = () => {
  const context = useContext(BadgeContext);
  if (!context) {
    throw new Error("useBadge must be used within a BadgeProvider");
  }
  return context;
};
