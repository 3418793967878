import React, { useEffect, useState } from "react";
import { BreadCurmbs } from "../../Breadcrumbs/Breadcrumbs";
import {
  Accordion,
  Card,
  Col,
  Container,
  ListGroup,
  Row,
} from "react-bootstrap";
import { getEnquiryHistory } from "../../../Api/enquireApi/EnquireHistory";
import { getSingleEnquiryHistory } from "../../../Api/enquireApi/EnquirySingleHistory";

const EnquiryHistory = () => {
  const [activeKey, setActiveKey] = useState(null);
  const [enquiryHistory, setEnquiryHistory] = useState("");
  const [singleEnquiryHistory, setSingleEnquiryHistory] = useState("");

  useEffect(() => {
    const updatehistoryEnquire = async () => {
      try {
        const historyEnquiry = await getEnquiryHistory();
        setEnquiryHistory(historyEnquiry.data);
      } catch (err) {
        console.log("error", err);
      }
    };
    updatehistoryEnquire();
  }, []);

  const handleAccordionClick = (index, id) => {
    setActiveKey((prevKey) =>
      prevKey === index.toString() ? null : index.toString()
    );
    updateEnquireSingle(id);
  };

  const updateEnquireSingle = async (id) => {
    try {
      const singleEnquiryHistoryupdate = await getSingleEnquiryHistory(id);
      setSingleEnquiryHistory(singleEnquiryHistoryupdate);
    } catch (err) {
      console.log("error", err);
    }
  };

  return (
    <div>
      {/* <BreadCurmbs title="Enquiry List " subtitle="Danfe Trading" /> */}
      <section
        className={`section-padding enquiryhistory-section`}
        style={{ paddingTop: "0" }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={12} lg={10} xs={12} className="row-col-history">
              <Card style={{ borderRadius: "0", marginBottom: "96px" }}>
                <Card.Body style={{ padding: "0" }}>
                  <Card.Header
                    style={{
                      background: "#fafafa",
                      borderRadius: "0",
                      padding: "13px 15px",
                    }}
                  >
                    <div className="product-enquiry-history">
                      <h6>Enquiry Number</h6>
                      <h6>Enquiry Date</h6>
                    </div>
                    <Card.Title
                      style={{ marginBottom: "0", fontSize: "16px" }}
                    ></Card.Title>
                  </Card.Header>

                  {enquiryHistory.length === 0 ? (
                    <span className="no-history">No history added..</span>
                  ) : (
                    <ListGroup variant="flush">
                      {Array.isArray(enquiryHistory) &&
                        enquiryHistory.map((item, index) => (
                          <ListGroup.Item>
                            <div className="product-history d-flex align-items-center">
                              <Accordion
                                activeKey={activeKey}
                                onSelect={() =>
                                  handleAccordionClick(index, item.id)
                                }
                              >
                                <Accordion.Item eventKey={index.toString()}>
                                  <Accordion.Header>
                                    <div className="action-history">
                                      <span
                                        className="status"
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.enquiry_number}
                                      </span>

                                      <span
                                        className="status-creatat"
                                        style={{
                                          color: "#1A68B2",
                                      
                                        }}
                                      >
                                        {item.created_at}
                                      </span>
                                    </div>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {singleEnquiryHistory && (
                                      <div>
                                        <ul>
                                          {singleEnquiryHistory?.data?.carts?.map(
                                            (cartItem, cartIndex) => (
                                              <li key={cartIndex}>
                                                <div className="enquiryproduct-history-details">
                                                  <div className="producthistory-image">
                                                    {cartItem.photo && (
                                                      <img
                                                        src={
                                                          cartItem.photo.split(
                                                            ","
                                                          )[0]
                                                        }
                                                        alt={`Product ${cartIndex}`}
                                                        style={{
                                                          maxWidth: "100%",
                                                        }}
                                                      />
                                                    )}
                                                    <p>{cartItem.product}</p>
                                                  </div>
                                                  <p
                                                    style={{
                                                      color: "#1A68B2",
                                                      fontSize: "18px",
                                                    }}
                                                  >
                                                    {cartItem.quantity}
                                                  </p>
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    )}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </div>
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default EnquiryHistory;
