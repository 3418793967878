import { axiosInstance } from "../Api";

export const getAddList = async () => {
  try {
    const updateList = await axiosInstance("enquiry/added-cart-list");
    return updateList.data;
  } catch (err) {
    console.log("error", err);
  }
};
