import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";

import { useBadge } from "../../Product/BadageContext";
import { GlobalContext } from "../../../Context/GlobalContext";

import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { getEnquireDoubleApi } from "../../../Api/enquireApi/EnquireDouble";

import "./popup.css";

const PopupList = ({ onClick }) => {
  const navigate = useNavigate();

  const { badgeCount, resetBadgeCount } = useBadge();

  const { profile } = useContext(GlobalContext);
  const [showPopup, setShowPopup] = useState(true);

  const [enquireData, setEnquireData] = useState({
    firstName: profile?.firstname || "",
    lastName: profile?.lastname || "",
    address: "",
    countryName: "",
    phoneNumber: "",
    emailId: profile?.email || "",
  });

  const [manualData, setManualData] = useState({
    address: "",
    // lastName: "",
    countryName: "",
    phoneNumber: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    countryName: "",
    address: "",
  });

  const handleClosePopup = () => {
    setShowPopup(false);
    onClick();
  };

  const validateForm = () => {
    const errors = {};
    console.log("errors", errors);
    if (!enquireData.firstName?.trim()) {
      errors.firstName = "First name is required";
    }

    if (!enquireData.lastName?.trim()) {
      errors.lastName = "Last name is required";
    }

    if (!enquireData.emailId?.trim()) {
      errors.emailId = "Email is required";
    }

    if (!manualData.phoneNumber?.trim()) {
      errors.phoneNumber = "Phonenumber is required";
    }

    if (!manualData.countryName?.trim()) {
      errors.countryName = "Counteryname is required";
    }

    if (!manualData.address?.trim()) {
      errors.address = "Address is required";
    }

    setValidationErrors(errors);

    return Object.values(errors).every((error) => error === "");
  };

  const getDoubleEnquireApi = async () => {
    try {
      if (badgeCount === 0) {
        toast.error("Product is not added. Please add the product.");
        return;
      }

      if (validateForm()) {
        const combinedData = {
          ...enquireData,
          ...manualData,
        };
        const updateDoubleEnquire = await getEnquireDoubleApi(combinedData);
        console.log("updateDoubleEnquir:::e", updateDoubleEnquire);
        navigate("/submitpage");
      } else {
        console.log("Validation failed. Please check the form for errors.");
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  return (
    <div className="popup-container">
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Enquire </Modal.Title>
          <RxCrossCircled className="icon-popup" onClick={handleClosePopup} />
        </Modal.Header>
        <Modal.Body
          style={{ paddingTop: "0" }}
          className="scrollable-modal-body"
        >
          <Form className="popup-row">
            <Row>
              <Col md={6} className="popup-col">
                <Form.Label>First Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  placeholder="Enter Name"
                  value={enquireData?.firstName}
                  onChange={(e) => {
                    setEnquireData({
                      ...enquireData,
                      firstName: e.target.value,
                    });
                    setValidationErrors((prevErrors) => ({
                      ...prevErrors,
                      firstName: "",
                    }));
                  }}
                />
                {validationErrors.firstName && (
                  <small className="text-danger" style={{ color: "red" }}>
                    {validationErrors.firstName}
                  </small>
                )}

                {enquireData.firstName !== profile?.firstname && (
                  <small className="text-danger" style={{ color: "red" }}>
                    First does not match the registered name.
                  </small>
                )}
              </Col>

              <Col md={6} className="popup-col">
                <Form.Label>Last Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder="Enter Name"
                  value={enquireData?.lastName}
                  onChange={(e) => {
                    setManualData({
                      ...enquireData,
                      lastName: e.target.value,
                    });
                    setValidationErrors((prevErrors) => ({
                      ...prevErrors,
                      lastName: "",
                    }));
                  }}
                />
                {validationErrors.lastName && (
                  <small className="text-danger" style={{ color: "red" }}>
                    {validationErrors.lastName}
                  </small>
                )}
              </Col>

              <Col md={6} className="popup-col">
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  placeholder="Enter Email"
                  value={enquireData?.emailId}
                  onChange={(e) => {
                    setEnquireData({
                      ...enquireData,
                      emailId: e.target.value,
                    });

                    setValidationErrors((prevErrors) => ({
                      ...prevErrors,
                      emailId: "",
                    }));
                  }}
                />
                {validationErrors.emailId && (
                  <small className="text-danger" style={{ color: "red" }}>
                    {validationErrors.emailId}
                  </small>
                )}

                {enquireData.emailId !== profile?.email && (
                  <small className="text-danger" style={{ color: "red" }}>
                    Email does not match the registered name.
                  </small>
                )}
              </Col>

              <Col md={6} className="popup-col">
                <Form.Label>Phone Number*</Form.Label>
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  placeholder="Enter Phone Number"
                  value={manualData?.phoneNumber}
                  // onChange={(e) =>
                  //   setManualData({
                  //     ...manualData,
                  //     phoneNumber: e.target.value,
                  //   })
                  // }

                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const isValidPhoneNumber = /^[0-9()+\- ]*$/.test(
                      inputValue
                    );

                    if (isValidPhoneNumber) {
                      setManualData({
                        ...manualData,
                        phoneNumber: inputValue,
                      });
                    }

                    setValidationErrors((prevErrors) => ({
                      ...prevErrors,
                      phoneNumber: "",
                    }));
                  }}
                />
                {validationErrors.phoneNumber && (
                  <small className="text-danger" style={{ color: "red" }}>
                    {validationErrors.phoneNumber}
                  </small>
                )}
              </Col>

              <Col md={6} className="popup-col">
                <Form.Label>Address*</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder="Enter Name"
                  value={manualData?.address}
                  onChange={(e) => {
                    setManualData({
                      ...manualData,
                      address: e.target.value,
                    });

                    setValidationErrors((prevErrors) => ({
                      ...prevErrors,
                      address: "",
                    }));
                  }}
                />
                {validationErrors.address && (
                  <small className="text-danger" style={{ color: "red" }}>
                    {validationErrors.address}
                  </small>
                )}
              </Col>

              <Col md={6} className="popup-col">
                <Form.Label>Country*</Form.Label>
                <Form.Control
                  type="text"
                  name="coutryName"
                  placeholder="Country"
                  value={manualData?.countryName}
                  onChange={(e) => {
                    setManualData({
                      ...manualData,
                      countryName: e.target.value,
                    });

                    setValidationErrors((prevErrors) => ({
                      ...prevErrors,
                      countryName: "",
                    }));
                  }}
                />
                {validationErrors.countryName && (
                  <small className="text-danger" style={{ color: "red" }}>
                    {validationErrors.countryName}
                  </small>
                )}
              </Col>

              <h6 className="popup-h6">Company Details(Option)</h6>
              <Col md={12} className="popup-col">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  name="company name"
                  placeholder="Enter Company Name"
                />
              </Col>

              <Col md={12} className="popup-col">
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                  type="text"
                  name="contactnumber"
                  placeholder="Enter Contact Number"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/[^0-9]/g, "");
                    e.target.value = numericValue;
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={(e) => {
              e.preventDefault();
              getDoubleEnquireApi();

              if (validateForm()) {
                handleClosePopup();
                resetBadgeCount();
              } else {
                console.log(
                  "Validation failed. Please check the form for errors."
                );
              }
            }}
            className="w-100 mb-4"
            style={{ backgroundColor: "#1A68B2" }}
          >
            Enquire Now
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PopupList;
