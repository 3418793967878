import React, { useContext, useEffect, useState } from "react";

import Col from "react-bootstrap/Col";

import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

import { getLogin } from "../../Api/AuthApi/LoginApi";
import { Button, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "./LoginRegister.css";
import { GlobalContext } from "../../Context/GlobalContext";
import PopupForget from "./PopupForget";

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  console.log(errors);

  const navigate = useNavigate();
  const location = useLocation();
  const [isFormValid, setIsFormValid] = useState();
  const { setProfile } = useContext(GlobalContext);

  const [showPassword, setShowPassword] = useState(false);
  const [showPopupForgetPassword, setShowPopupForgetPassword] = useState(false);

  const registeredEmail = location.state?.registeredEmail || "";
  const registeredPassword = location.state?.registeredPassword || "";

  const handleLogin = async (data) => {
    if (isFormValid) {
      try {
        const updateLogin = await getLogin({
          email: data.email,
          password: data.password,
        });

        console.log("updatelogin", updateLogin);
        const successfulllogin = true;

        if (updateLogin.success) {
          console.log("hi update:", updateLogin);
          setProfile(updateLogin);
        } else if (successfulllogin) {
          navigate("/");

          toast.success("You have successfully logged in");
        }
        setTimeout(() => {
          window.location.reload();
        }, 100);
        
      } catch (err) {
        console.log("error", err);
        if (err.response && err.response.data && err.response.errors) {
          toast.error("Network error: " + err.response.data.errors);
        } else {
          toast.error("Email or Password incorrect. Please try again.");
        }
      }
    }
  };

  useEffect(() => {
    setIsFormValid(isValid);
  }, [isValid]);

  const handleKeyPress = (event) => {
    if (event.key === "enter") {
      handleLogin();
    }
  };

  const handleClickForget = () => {
    setShowPopupForgetPassword(true);
    console.log("Forget password clicked");
  };

  const handleClosePopup = () => {
    setShowPopupForgetPassword(false);
  };

  return (
    <>
      <section className="section-padding">
        <Container>
          <Row className="justify-content-center">
            <Col sm={9} md={6} lg={5}>
              <div className="login-form">
                <h2 className="title">Login</h2>
                <Form
                  onSubmit={handleSubmit(handleLogin)}
                  className="form-control-login"
                >
                  <Form.Group
                    controlId="validationCustomemail"
                    className="mb-4 position-relative"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      onKeyPress={handleKeyPress}
                      placeholder="Enter your email"
                      {...register("email", {
                        required: "email is required",
                        pattern: {
                          value: /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi,
                          message: "email is not valid",
                        },
                      })}
                      defaultValue={registeredEmail}
                    />
                    {errors.email && (
                      <p className="error-message" style={{ color: "red" }}>
                        {errors.email.message}
                      </p>
                    )}
                  </Form.Group>

                  <Form.Group
                    controlId="validationCustom02"
                    className="mb-4 position-relative"
                  >
                    <Form.Label>Password</Form.Label>
                    <div className="position-relative d-flex align-items-center login-password">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter your password"
                        name="password"
                        onKeyPress={handleKeyPress}
                        // value={password}
                        // onChange={handlePasswordChange}
                        {...register("password", {
                          required: "Password is required",
                        })}
                        defaultValue={registeredPassword}
                      />
                      <span
                        className="showpassword"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </span>
                    </div>

                    {errors.password && (
                      <p className="error-message" style={{ color: "red" }}>
                        {errors.password.message}
                      </p>
                    )}
                  </Form.Group>
                  <div
                    className="login-forget-password"
                    onClick={(e) => {
                      e.preventDefault();
                      handleClickForget();
                    }}
                    // onClick={handleClickForget}
                    style={{ cursor: "pointer" }}
                  >
                    Forget password
                  </div>
                  <Form.Group className=" mb-4 position-relative text-right">
                    {/* <Link to="/change-password" className="pw-reset">
                      Reset Password ?
                    </Link> */}
                  </Form.Group>
                  <Button type="submit" className="cl-button w-100 text-center">
                    Login
                  </Button>
                </Form>

                <div className="register-link">
                  <p>
                    Do not have an account?
                    <Link to="/register">Register here</Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {showPopupForgetPassword && <PopupForget onClick={handleClosePopup} />}
    </>
  );
};
