import { axiosInstance, getTokenFromStorage } from "../Api";

export const getListEnquire = async (slug) => {
  try {
    const api_token = getTokenFromStorage();
    const updateSingleEnquire = await axiosInstance.get(
      `/enquiry/add-to-cart/single?slug=${slug}`
    );
    return updateSingleEnquire.data;
  } catch (err) {
    console.log("error", err);
  }
};
