import { axiosInstance } from "../Api";

export const getSingleEnquiryHistory = async (id) => {
  try {
    const updateSingleEnquiry = await axiosInstance.get(
      `/enquiry-history-single?id=${id}`
    );
    return updateSingleEnquiry.data;
  } catch (err) {
    console.log("error", err);
  }
};
