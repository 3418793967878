import React, { useEffect, useState } from "react";

import { Container } from "react-bootstrap";
import { MoonLoader } from "react-spinners";
import ReactOwlCarousel from "react-owl-carousel";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

import { getProductApi } from "../../../Api/productApi/ProductApi";
import { getProductDetailApi } from "../../../Api/productApi/ProductDetail";
import Loading from "../../loader/Loading";

export const SimilarProdcut = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [itemDetail, setItemDetail] = useState([]);
  const [similarProduct, setSimilarProduct] = useState("");

  const productslider = {
    loop: true,
    margin: 0,
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };

  useEffect(() => {
    const getApiProduct = async () => {
      try {
        const updateGetAllProduct = await getProductApi();
        console.log("updateGetAllProduct", updateGetAllProduct);
        setSimilarProduct(updateGetAllProduct.data);
        setLoading(false);
      } catch (err) {
        console.log("error", err);
      }
    };
    getApiProduct();
  }, []);

  const handleClick = async (slug) => {
    console.log("Clicked on product with id:", slug);

    try {
      const updateDetails = await getProductDetailApi(slug);
      console.log("updatDetails", updateDetails);
      const adjustedIndex = slug - 1;

      navigate(`/product-detail?slug=${slug}`, {
        state: { productData: updateDetails, slug: adjustedIndex },
      });
      setItemDetail(itemDetail);
      window.scrollTo({ top: 0, behavior: "smooth" });

      console.log("setItemDetail", itemDetail);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <section className="section-padding">
        <Container>
          <div className="cl-header">
            <h2 className="title">Similar Products</h2>
          </div>
          {loading || similarProduct?.length === 0 ? (
            <div className="loader-similarProduct">
              <Loading />
            </div>
          ) : (
            <ReactOwlCarousel
              className="owl-theme owl-carousel top-header-details product-carousel"
              {...productslider}
            >
              {Array.isArray(similarProduct) &&
                similarProduct.map((data, index) => (
                  <div className="item" key={index}>
                    <div
                      className="product-wrap"
                      onClick={() => handleClick(data.slug)}
                    >
                      <Link to="/allproduct">
                        <div className="img-wrap">
                          <img src={data.photo} alt="brand1" />
                        </div>
                        <h6 className="title">{data.title}</h6>
                      </Link>
                      <Link
                        to=""
                        className="morebtn"
                        style={{ Color: "var(--cl-primary-color)" }}
                      >
                        Enquire
                      </Link>
                    </div>
                  </div>
                ))}
            </ReactOwlCarousel>
          )}

          <div className="morebtn-wrap text-right">
            <Link
              to="/allproduct"
              className="morebtn"
              style={{ marginBottom: "96px" }}
            >
              All Products <IoIosArrowForward className="icon" />
            </Link>
          </div>
        </Container>
      </section>
    </>
  );
};
