import React, { useContext, useEffect, useRef, useState } from "react";

import { toast } from "react-toastify";
import { HiPencil } from "react-icons/hi2";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { GlobalContext } from "../../../Context/GlobalContext";
import PopupsavechangePassword from "./PopupsavechangePassword";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

import { getEditProfile } from "../../../Api/AuthApi/EditProfileApi";
import EnquiryHistory from "../../innerPage/EnquiryList/EnquiryHistory";
import { getChangePassword } from "../../../Api/AuthApi/ChangePasswordApi";

import "./Profile.css";
import "react-tabs/style/react-tabs.css";

export const Profile = () => {
  const formRef = useRef(null);
  const fileInputRef = useRef(null);

  const { profile } = useContext(GlobalContext);

  const [errorValid, setErrorValid] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [requiredInput, setRequiredInput] = useState("");
  const [passwordMatch, setPasswordMatch] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [showNpassword, setShowNpassword] = useState(false);
  const [showCpassword, setShowCpassword] = useState(false);

  const [state, setState] = useState(profile?.state || "");
  const [country, setCountry] = useState(profile?.country || "");
  const [zipCode, setZipCode] = useState(profile?.zip_code || "");
  const [editEmail, setEditEmail] = useState(profile?.email || "");
  const [profilePhoto, setProfilePhoto] = useState(profile?.photo || "");
  const [editLastName, setEditLastName] = useState(profile?.lastname || "");
  const [phoneNumber, setPhoneNumber] = useState(profile?.phone_number || "");
  const [editFirstName, setEditFirstName] = useState(profile?.firstname || "");

  const [profilePhotoBinary, setProfilePhotoBinary] = useState(
    profile?.photo || ""
  );

  const [changePasswordFormData, setChangePasswordFormData] = useState({
    currentpassword: "",
    newpassword: "",
    confirmpassword: "",
  });

  const handleShowpopup = () => {
    try {
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 400) {
        setShowPopup(false);
      }

      console.log(err);
      throw err;
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    setState(profile?.state || "");
    setEditFirstName(profile?.firstname || "");
    setEditLastName(profile?.lastname || "");
    setEditEmail(profile?.email || "");
    setCountry(profile?.country || "");
    setZipCode(profile?.zip_code || "");
    setProfilePhoto(profile?.photo || "");
    setPhoneNumber(profile?.phone_number || "");
  }, [profile]);

  const handleProfileSave = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("photo", profilePhotoBinary);
      formData.append("firstname", editFirstName);
      formData.append("lastname", editLastName);
      formData.append("email", editEmail);
      formData.append("country", country);
      formData.append("state", state);
      formData.append("zip_code", zipCode);
      formData.append("phone_number", phoneNumber);

      const editProfileResponse = await getEditProfile(formData);
      console.log("editProfileResponse;;;;", editProfileResponse);

      toast.success(" Profile is changed successfully ", {
        position: "top-center",
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangePasswordFormFeild = (e) => {
    const { name, value } = e.target;
    setErrorValid("");

    setChangePasswordFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "confirmpassword") {
      const newPass = changePasswordFormData.newpassword;
      const confirmPass = value;

      for (let i = 0; i < confirmPass?.length; i++) {
        if (newPass[i] !== confirmPass[i]) {
          setPasswordMatch("Passwords not matching");
          return;
        }
      }
      setPasswordMatch("");
    }
  };

  const fetchChangePaswword = async (e) => {
    e?.preventDefault();
    if (
      !changePasswordFormData.currentpassword ||
      !changePasswordFormData.newpassword ||
      !changePasswordFormData.confirmpassword
    ) {
      setRequiredInput("Required input fields ");
      setShowPopup(false);
      return;
    }

    try {
      const getPasswordResponse = await getChangePassword(
        changePasswordFormData
      );
      console.log("Change Password Response:", getPasswordResponse);
      if (getPasswordResponse.success) {
        toast.success("Password is changed successfully");
        setShowPopup(true);
      }
    } catch (err) {
      console.log(err);
      if (err.message === "Password does not match") {
        setErrorValid("Current password does not match");
        setShowPopup(false);
      } else {
        setErrorValid("Something went wrong. Please try again.");
      }
    }
  };

  const handleReset = () => {
    formRef.current?.reset();
    setChangePasswordFormData({
      currentpassword: "",
      newpassword: "",
      confirmpassword: "",
    });

    setTimeout(() => {
      setRequiredInput("");
    }, 0);
    setPasswordMatch("");
    toast.success("You have cancelled to change password", {
      position: "top-center",
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = function (e) {
      setProfilePhoto(e.target.result);
      setProfilePhotoBinary(file);
    };

    reader.readAsDataURL(file);
  };

  // const hasPhoto = () => {
  //   const linkArray = profilePhoto.split("/");

  //   return linkArray[linkArray.length - 1];
  // };

  // const getFirstLetterOfName = () => {
  //   return editName.charAt(0).toUpperCase();
  // };

  const handleSaveChange = () => {
    setSaveClicked(true);
  };

  const handleChangePhoneNumber = (e) => {
    const inputValue = e.target.value;
    const isValidPhoneNumber = /^[0-9()+\- ]*$/.test(inputValue);

    if (isValidPhoneNumber) {
      setPhoneNumber(inputValue);
    }
  };

  return (
    <>
      <section className="section-padding profile-section">
        <Container>
          <Tabs className="custom-tabs">
            <div style={{ overflowX: "auto" }} className="custom">
              <TabList>
                <Tab>Profile</Tab>
                <Tab>Enquiry History</Tab>
                <Tab>Change Password</Tab>
              </TabList>
            </div>

            <TabPanel>
              <div
                className="profile-wrap border-wrap"
                style={{ marginBottom: "96px" }}
              >
                <div className="img-wrap">
                  <label htmlFor="fileInput">
                    {/* {hasPhoto() ? ( */}
                    {profilePhoto ? (
                      <img
                        id="profile-image"
                        src={profilePhoto}
                        alt="Profile"
                        style={{
                          borderRadius: "100%",
                          backgroundColor: "green",
                        }}
                      />
                    ) : (
                      <img
                        id="profile-image"
                        src={`https://dummyimage.com/150x150/000/fff&text=${
                          editFirstName
                            ? editFirstName.charAt(0).toUpperCase()
                            : ""
                        }`}
                        alt="Profile"
                        style={{
                          borderRadius: "100%",
                          backgroundColor: "green",
                        }}
                      />
                    )}

                    <div className="icon-wrap">
                      <HiPencil className="icon" style={{ color: "white" }} />
                    </div>
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                </div>

                <div className="edit-form">
                  <Form noValidate ref={formRef}>
                    <Row>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                        className="mb-4 position-relative"
                      >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Your Name"
                          value={editFirstName}
                          onChange={(e) => setEditFirstName(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                        className="mb-4 position-relative"
                      >
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Your Name"
                          value={editLastName}
                          onChange={(e) => setEditLastName(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                        className="mb-4 position-relative"
                      >
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          required
                          type="gmail"
                          placeholder="Email"
                          value={editEmail}
                          onChange={(e) => setEditEmail(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                        className="mb-4 position-relative"
                      >
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          inputMode="numeric"
                          placeholder="Enter your phone number"
                          value={phoneNumber}
                          onChange={handleChangePhoneNumber}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                        className="mb-4 position-relative"
                      >
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="country"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                        className="mb-4 position-relative"
                      >
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder=" State"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                        className="mb-4 position-relative"
                      >
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder=" Zip code"
                          value={zipCode}
                          onChange={(e) => setZipCode(e.target.value)}
                        />
                      </Form.Group>
                    </Row>
                    <Button
                      type="submit"
                      className="cl-button  text-center"
                      onClick={handleProfileSave}
                      // onClick={handleProfileSave}
                      // style={{ display: profileEdit ? "block" : "none" }}
                    >
                      Save Changes
                    </Button>
                  </Form>
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <EnquiryHistory />
            </TabPanel>

            <TabPanel>
              <div className="change-form ">
                <Row
                  className="justify-content-center"
                  style={{ marginBottom: "96px" }}
                >
                  <Col md={5} className="form-col-changepassword">
                    <Form
                      noValidate
                      className="border-wrap"
                      onSubmit={(e) => fetchChangePaswword(e)}
                    >
                      <Form.Group
                        controlId="validationCustom02"
                        className="mb-4 position-relative"
                      >
                        <Form.Label>Enter Current Password</Form.Label>
                        <div className="position-relative d-flex align-items-center">
                          <Form.Control
                            required
                            type={showPassword ? "text" : "password"}
                            name="currentpassword"
                            value={changePasswordFormData.currentpassword}
                            onChange={handleChangePasswordFormFeild}
                          />
                          <span
                            className="showpassword"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? "Hide" : "Show"}
                          </span>
                        </div>
                        {errorValid && (
                          <p className="error-message" style={{ color: "red" }}>
                            {errorValid}
                          </p>
                        )}

                        {!changePasswordFormData.currentpassword &&
                          requiredInput && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {requiredInput}
                            </p>
                          )}
                      </Form.Group>

                      <Form.Group
                        controlId="validationCustom02"
                        className="mb-4 position-relative"
                      >
                        <Form.Label>Enter New Password</Form.Label>
                        <div className="d-flex align-items-center position-relative">
                          <Form.Control
                            required
                            type={showNpassword ? "text" : "password"}
                            name="newpassword"
                            value={changePasswordFormData.newpassword}
                            onChange={handleChangePasswordFormFeild}
                          />
                          <span
                            className="showpassword"
                            onClick={() => setShowNpassword(!showNpassword)}
                          >
                            {showNpassword ? "Hide" : "Show"}
                          </span>
                        </div>
                        {!changePasswordFormData.newpassword &&
                          requiredInput && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {requiredInput}
                            </p>
                          )}
                        {saveClicked &&
                          changePasswordFormData.newpassword ===
                            changePasswordFormData.currentpassword && (
                            <p style={{ color: "red" }}>
                              This password is already used
                            </p>
                          )}
                      </Form.Group>

                      <Form.Group
                        controlId="validationCustom02"
                        className="mb-4 position-relative"
                      >
                        <Form.Label>Enter Confrim Password</Form.Label>
                        <div className="d-flex align-items-center position-relative">
                          <Form.Control
                            type={showCpassword ? "text" : "password"}
                            name="confirmpassword"
                            value={changePasswordFormData.confirmpassword}
                            onChange={handleChangePasswordFormFeild}
                          />
                          <span
                            className="showpassword"
                            onClick={() => setShowCpassword(!showCpassword)}
                          >
                            {showCpassword ? "Hide" : "Show"}
                          </span>
                        </div>
                        {passwordMatch && (
                          <p style={{ color: "red" }}>{passwordMatch}</p>
                        )}
                        {!changePasswordFormData.confirmpassword &&
                          requiredInput && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {requiredInput}
                            </p>
                          )}
                      </Form.Group>
                      <div
                        className="botton-changepassword"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          type="submit"
                          className="cl-button-change text-center"
                          onClick={() => {
                            handleShowpopup();
                            handleSaveChange();
                          }}
                          style={{ backgroundColor: "#1A68B2" }}
                        >
                          Save Changes
                        </Button>
                        <button
                          to="/login"
                          className="ms-5 cancle-button"
                          onClick={handleReset}
                          style={{
                            cursor: "pointer",
                            height: "50px",
                            width: "92px",
                            border: "none",
                            borderRadius: "5px",
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </div>
            </TabPanel>
          </Tabs>
        </Container>
        {showPopup && (
          <PopupsavechangePassword
            onClick={handleClosePopup}
            onPopup={setShowPopup}
          />
        )}
      </section>
    </>
  );
};
