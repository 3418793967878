import React from "react";

import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

import "./Error.css";

export const Error = () => {
  return (
    <>
      <section className="section-padding error-section">
        <Container>
          <Row>
            <Col sm={8} md={7} lg={5}>
              <div className="error-wrap">
                <div className="img-wrap">
                  <img src="/images/error.png" alt="error" />
                </div>
                <div className="content">
                  <h2 className="title">Oops! Page not found.</h2>
                  <p>
                    We can't find the page you're looking for. You can either
                    return to the previous page, visit our home page.
                  </p>
                  <Link to="/" className="cl-button w-100">
                    Go Back
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
