import React, { useEffect } from "react";
import "./Product.css";
import { ProductItemDetail } from "./ProductDetailSection/ProductItemDetail";
import { SimilarProdcut } from "./ProductDetailSection/SimilarProduct";

export const Productdetail = () => {
  return (
    <>
      <ProductItemDetail />
      <SimilarProdcut />
    </>
  );
};
