import { axiosInstance } from "../Api";

export const getReasonWhyApi = async () => {
  const params = new URLSearchParams();
  params.set("pagename", "Reasons To Choose Us");

  try {
    const updateReasonwhyUsApi = await axiosInstance.post(
      `/cms?${params?.toString()}`
    );
    return updateReasonwhyUsApi.data;
  } catch (err) {
    console.log("error", err);
  }
};
