import React, { useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";

import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { getRegister } from "../../Api/AuthApi/RegisterApi";

import "./LoginRegister.css";

export const Register = () => {
  const navigate = useNavigate();

  const [isFormValid, setIsFormValid] = useState();
  const [showpassword, setShowpassword] = useState(false);
  const [showCpassword, setShowCpassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
  } = useForm();

  const handleRegister = async (data) => {
    if (isFormValid) {
      try {
        const updateregister = await getRegister({
          firstname: data?.first,
          lastname: data?.last,
          email: data?.email,
          password: data?.password,
          confirm_password: data?.cPassword,
        });
        console.log("register success:", updateregister);

        if (updateregister.message === "User successfully registered") {
          toast.success("User successfully registered");

          navigate("/login", {
            state: {
              registeredEmail: data.email,
              registeredPassword: data.password,
            },
          });
        }
      } catch (err) {
        toast.error("Already register this account, Please try again:");
        console.log("Register Failed:", err);
      }
    }
  };

  useEffect(() => {
    setIsFormValid(isValid);
  }, [isValid]);

  return (
    <>
      <section className="register-section section-padding">
        <Container>
          <Row className="justify-content-center">
            <Col sm={9} md={6} lg={5}>
              <div className="login-form register-form">
                <h2 className="title">Register</h2>
                <Form
                  onSubmit={handleSubmit(handleRegister)}
                  className="form-control-register"
                >
                  <Form.Group
                    controlId="validationCustomfullname"
                    className="mb-4 position-relative"
                  >
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your first name"
                      defaultValue=""
                      {...register("first", {
                        required: "First Name is required",
                        pattern: {
                          value: /^[\w\s]+$/,
                          message: "First Name is not valid",
                        },
                      })}
                    />
                    {errors.first && (
                      <p className="error-message" style={{ color: "red" }}>
                        {errors.first.message}
                      </p>
                    )}
                  </Form.Group>

                  <Form.Group
                    controlId="validationCustomfullname"
                    className="mb-4 position-relative"
                  >
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your last name"
                      defaultValue=""
                      {...register("last", {
                        required: "Last Name is required",
                        pattern: {
                          value: /^[\w\s]+$/,
                          message: "Full Name is not valid",
                        },
                      })}
                    />
                    {errors.last && (
                      <p className="error-message" style={{ color: "red" }}>
                        {errors.last.message}
                      </p>
                    )}
                  </Form.Group>

                  <Form.Group
                    controlId="validationCustom02"
                    className="mb-4 position-relative"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your email "
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid email address .",
                        },
                      })}
                    />
                    {errors.email && (
                      <p className="error-message" style={{ color: "red" }}>
                        {errors.email.message}
                      </p>
                    )}
                  </Form.Group>

                  <Form.Group controlId="validationCustom03" className="mb-4 ">
                    <Form.Label> Password</Form.Label>
                    <div className="position-relative d-flex align-items-center">
                      <Form.Control
                        type={showpassword ? "text" : "password"}
                        placeholder="Enter your password"
                        {...register("password", {
                          required: "Password is required",
                          pattern: {
                            value:
                              // /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).[a-zA-Z0-9!@#$%^&*].{8}$/gm,
                              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{5,}$/,
                            message: `
                              Must contain at least 1 uppercase letter, 1 lowercase letter,
                               1 number and 1 special character\n `,
                          },
                        })}
                      />
                      <span
                        className="showpassword"
                        onClick={() => setShowpassword(!showpassword)}
                      >
                        {showpassword ? "Hide" : "Show"}
                      </span>
                    </div>
                    {errors.password && (
                      <p className="error-message" style={{ color: "red" }}>
                        {errors.password.message}
                      </p>
                    )}
                  </Form.Group>

                  <Form.Group
                    controlId="validationCustom04"
                    className="mb-4 position-relative"
                  >
                    <Form.Label>Confirm Password</Form.Label>
                    <div className="position-relative d-flex align-items-center">
                      <Form.Control
                        type={showCpassword ? "text" : "password"}
                        placeholder="Enter your confirm-password"
                        {...register("cPassword", {
                          required: "Confirm Password is required",
                          //   validate: (value, formvalue) =>
                          //     value === formvalue.password ||
                          //     "Passwords do not match",
                          // })}
                          validate: (value, formValue) =>
                            !formValue.password ||
                            value === formValue.password ||
                            "Passwords do not match",
                        })}
                        onBlur={() => trigger("cPassword")}
                      />
                      <span
                        className="showpassword"
                        onClick={() => setShowCpassword(!showCpassword)}
                      >
                        {showCpassword ? "Hide" : "Show"}
                      </span>
                    </div>
                    {errors.cPassword && (
                      <p className="error-message" style={{ color: "red" }}>
                        {errors.cPassword.message}
                      </p>
                    )}
                  </Form.Group>

                  <Form.Group
                    controlId="validationCustom05"
                    className="position-relative mb-4"
                  >
                    <Form.Check
                      className="register-agree-terms"
                      label="Agree to terms and conditions"
                      feedbackType="invalid"
                      {...register("terms", {
                        required: "You must agree before submitting.",
                      })}
                    />
                    {errors.terms && (
                      <p className="error-message" style={{ color: "red" }}>
                        {errors.terms.message}
                      </p>
                    )}
                  </Form.Group>

                  <Button type="submit" className="cl-button w-100 text-center">
                    Register Account
                  </Button>
                </Form>

                <p className="have-account">
                  Already have an account?
                  <Link to="/login">Login here</Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
