import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Col, Row } from "react-bootstrap";
import { MoonLoader } from "react-spinners";
import Carousel from "react-bootstrap/Carousel";

import { IoIosArrowForward } from "react-icons/io";
import Container from "react-bootstrap/esm/Container";
import { getBannerApi } from "../../../Api/banner/Banner";

import "./Banner.css";

import parse from "html-react-parser";
import Loading from "../../loader/Loading";
import { getCardApi } from "../../../Api/about/About";

export const Banner = () => {
  const [loading, setLoading] = useState(true);
  const [bannerImage, setBannerImage] = useState([]);
  const [card1, setcard1] = useState("");

  useEffect(() => {
    const BannerProductApi = async () => {
      try {
        const updateBanner = await getBannerApi();
        setBannerImage(updateBanner.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    BannerProductApi();
  }, []);

  useEffect(() => {
    const updateCard1Page = async () => {
      try {
        const updateCare1 = await getCardApi();
        setcard1(updateCare1.data);
      } catch (err) {
        console.log("error", err);
      }
    };
    updateCard1Page();
  }, []);
  return (
    <>
      <section
        className="cl-banner banner-section"
        // style={{ paddingTop: "48px" }}
      >
        <Container>
          <Row className="">
            <Col md={7}>
              <div className="banner-content">
                {loading || bannerImage?.length === 0 ? (
                  <div className="loader-container">
                    <Loading />
                  </div>
                ) : (
                  <Carousel className="">
                    {Array.isArray(bannerImage) &&
                      bannerImage?.map((items, index) => (
                        <Carousel.Item>
                          <React.Fragment key={index}>
                            <div className="carousel-image">
                              <img src={items.photo} alt="slider" />
                              <div className="sub-title">
                                <span className="subtitle">
                                  {items.subtitle}
                                </span>
                                <h1 className="main-title">{items.title}</h1>

                                <p>
                                  {items.description &&
                                    typeof items.description === "string" &&
                                    parse(items.description)}
                                </p>
                                <Link
                                  to="/allproduct"
                                  className="cl-button bannershopsection-btn"
                                >
                                  Shop Now
                                  <IoIosArrowForward className="icon-shop" />
                                </Link>
                              </div>
                            </div>
                          </React.Fragment>
                        </Carousel.Item>
                      ))}
                  </Carousel>
                )}
              </div>
            </Col>

            <Col md={5}>
              <div className="banner-carousel">
                <div className="organic-product">
                  {card1[0]?.blocks?.map((item, index) => (
                    <React.Fragment key={index}>
                      <h2>{item.title}</h2>

                      <p>{item.content && parse(String(`${item.content}`).slice(0, 200))}</p>
                    </React.Fragment>
                  ))}

                  <Link to="/allproduct" className="organic-icon">
                    Shop Now
                    <IoIosArrowForward
                      className="product-icon-shop"
                      style={{ fontSize: "15px" }}
                    />
                  </Link>
                </div>

                <div className="img-wrap">
                  <img src={card1[0]?.blocks[0]?.image} alt="image"></img>
                </div>
                {/* {Array.isArray(bannerImage) ? (
                  bannerImage?.map((items, index) => (
                    <Carousel.Item>
                      <div className="img-wrap" key={index}>
                        <img src={items.photo} alt="slider" />
                     
                      </div>
                    </Carousel.Item>
                  ))
                ) : (
                  <p>Loading...</p>
                )} */}

                {/*<Carousel.Item>
                  <div className="img-wrap">
                    <img src="images/slider.png" alt="slider" />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="img-wrap">
                    <img src="images/slider.png" alt="slider" />
                  </div>
                </Carousel.Item> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
