import { axiosInstance } from "../Api";

export const getContactFormApi = async (userData) => {
  console.log("userData.......", userData);
  const params = new URLSearchParams();
  params.set("subject", userData.subject);
  params.set("name", userData.name);
  params.set("email", userData.email);
  params.set("message", userData.message);
  console.log("params", params.toString());
  try {
    const updateContactForm = await axiosInstance.post(
      `/contact/mail?${params.toString()}`
    );
    console.log("updateContactForm;;;;", updateContactForm);
    return updateContactForm.data;
  } catch (err) {
    console.log("error", err);
  }
};
