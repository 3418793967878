import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTopOnNavigation = () => {
  
  const { pathname, search } = useLocation();

  useEffect(() => {
     const shouldScrollToTop = !(
       pathname.includes("allproduct") && search.includes("per_page=9")
     );

     if (shouldScrollToTop) {
       window.scrollTo(0, 0);
     }
  }, [pathname]);

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    return () => {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "auto";
      }
    };
  }, []);

  return null;
}

export default ScrollToTopOnNavigation;
