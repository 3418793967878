import { axiosInstance } from "../Api";

export const getContactApi = async () => {
  try {
    const contactApi = await axiosInstance.get("/contact");
    return contactApi.data;
  } catch (err) {
    console.log("error", err);
  }
};
