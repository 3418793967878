import React, { useEffect, useState } from "react";

import "./resetpassword.css";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
  getResetPassword,
  getValidationCode,
} from "../../Api/forgetpassword/ForgetPasswordApi";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showpassword, setShowpassword] = useState(false);
  const [showCpassword, setShowCpassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  useEffect(() => {
    const handle = async (code) => {
      const token = await getValidationCode(code);
      if (!token || token.status === 400) {
        toast.error("Verification code has expired");
        navigate("/login");
        return;
      }
    };
    handle();
  }, []);

  const handleResetPassword = async (e) => {
    e.preventDefault();

    // Custom validation

    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{5,}$/;
    if (!passwordRegex.test(newPassword)) {
      setPasswordError(`
        Must contain at least 1 uppercase letter, 1 lowercase letter,
        1 number, and 1 special character. Minimum length: 5 characters
      `);
      return;
    } else {
      setPasswordError("");
    }

    if (newPassword !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return;
    } else {
      setConfirmPasswordError("");
    }

    // Reset password logic
    try {
      const resetCode = getResetPassword();
      const updateResetPassword = await getResetPassword(
        resetCode,
        newPassword,
        confirmPassword
      );
      console.log("updateResetPassword;;;;;;;;:::", updateResetPassword);
      navigate("/login");

      toast.success("Password reset successfully");
    } catch (err) {
      console.log("Reset Password Failed:", err);
    }
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setPasswordError(""); // Clear password error when typing
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    let mismatch = false;

    // Check for password mismatch for each character
    for (let i = 0; i < value.length; i++) {
      if (newPassword[i] !== value[i]) {
        mismatch = true;
        break;
      }
    }

    if (mismatch && value.length !== 0) {
      // Show error message only if the confirm password doesn't match newPassword and is not empty
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  };

  return (
    <div>
      <section className="section-padding">
        <Container>
          <Row className="justify-content-center">
            <Col sm={9} md={6} lg={5}>
              <div className="login-form">
                <h2 className="title">Reset Password</h2>
                <Form
                  className="form-control-login"
                  onSubmit={handleResetPassword}
                >
                  <Form.Group
                    controlId="validationCustomemail"
                    className="mb-4 position-relative"
                  >
                    <Form.Label>New Password</Form.Label>
                    <div className="position-relative d-flex align-items-center">
                      <Form.Control
                        type={showpassword ? "text" : "password"}
                        name="password"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                      />

                      <span
                        className="showpassword"
                        onClick={() => setShowpassword(!showpassword)}
                      >
                        {showpassword ? "Hide" : "Show"}
                      </span>
                    </div>

                    {passwordError && (
                      <Form.Text className="text-danger">
                        {passwordError}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group
                    controlId="validationCustom02"
                    className="mb-4 position-relative"
                  >
                    <Form.Label>Confirm Password</Form.Label>
                    <div className="position-relative d-flex align-items-center">
                      <Form.Control
                        type={showCpassword ? "text" : "password"}
                        placeholder="Enter your confirm password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                      />

                      <span
                        className="showpassword"
                        onClick={() => setShowCpassword(!showCpassword)}
                      >
                        {showCpassword ? "Hide" : "Show"}
                      </span>
                    </div>
                    {confirmPasswordError && (
                      <Form.Text className="text-danger">
                        {confirmPasswordError}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Button
                    className="cl-button w-100 text-center button-btn-rese"
                    type="submit"
                    style={{ marginTop: "30px", marginBottom: "96px" }}
                  >
                    Reset password
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default ResetPasswordPage;
