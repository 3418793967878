import { axiosInstance } from "../Api";

export const getAddToCartEnquire = async (slug) => {
  try {
    const updateAddToCard = await axiosInstance.get(
      `/enquiry/add-to-cart?slug=${slug}&timestamp=${new Date().getTime()}`,
      {
        headers: { "Cache-Control": "no-cache" },
        maxRedirects: 0,
      }
    );

    console.log("updateAddToCard", updateAddToCard);
    return updateAddToCard.data;
  } catch (error) {
    console.log("error", error);
  }
};
