import React, { useContext, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { FaTiktok } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";

import { AiOutlineHome } from "react-icons/ai";
import { LiaPhoneSolid } from "react-icons/lia";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { AiOutlineInstagram } from "react-icons/ai";

import { BiLogoFacebookCircle } from "react-icons/bi";
import { Col, Container, Row } from "react-bootstrap";
import { getContactApi } from "../../Api/contactApi/Contact";

import "./Footer.css";
import { ApiContext } from "../Product/ContextApiCategory";

export const Footer = () => {
  const { category, handleClickCategory, activeCategory, setActiveCategory } =
    useContext(ApiContext);
  const [footerContact, setFooterContact] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    const getFooterApi = async () => {
      try {
        const updateFooter = await getContactApi();
        setFooterContact(updateFooter.data[0]);
      } catch (err) {
        console.log("error", err);
      }
    };
    getFooterApi();
  }, []);

  const handleAboutPageTopScroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleClickMore = async () => {
    navigate("/allproduct");
    setActiveCategory("all");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <footer className="cl-footer">
        <div className="footer-container">
          <Container>
            <Row className="row-footer">
              <Col lg={4}>
                <div className="footer-about">
                  <div className="logo-wrap">
                    <Link to="/">
                      <img src="images/logo-1.png" alt="logo-1" />
                    </Link>
                  </div>
                  <p>
                    Danphe Trading Ltd is an importer, wholesaler & distributor
                    of all Nepalese products. It is run by professionals who had
                    had over 20 years of experience in Nepalese products
                  </p>
                  <ul className="social-icon">
                    <li>
                      <Link to={footerContact?.facebook}>
                        <BiLogoFacebookCircle className="icon fb" />
                      </Link>
                    </li>
                    <li>
                      <Link to={footerContact?.instagram}>
                        <AiOutlineInstagram className="icon fb" />
                      </Link>
                    </li>
                    <li>
                      <Link to={footerContact?.tiktok}>
                        <FaTiktok
                          className="icon fb"
                          style={{ fontSize: "19px" }}
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to={footerContact?.twitter}>
                        <FaXTwitter
                          className="icon fb"
                          style={{ fontSize: "19px" }}
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={{ span: 7, offset: 1 }}>
                <Row>
                  <Col lg={4} md={6}>
                    <div className="footer-link">
                      <h6 className="footer-title useful-link">Quick Links</h6>
                      <ul>
                        <li>
                          <Link to="/">Home</Link>
                        </li>

                        <li>
                          <Link to="/about" onClick={handleAboutPageTopScroll}>
                            About
                          </Link>
                        </li>

                        <li>
                          <Link to="/allproduct">Product</Link>
                        </li>

                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>

                        <li>
                          <Link to="/allbrands">Brand</Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg={4} md={6}>
                    <div className="footer-link help-link">
                      <h6 className="footer-title">Category</h6>
                      {Array.isArray(category) &&
                        category.slice(0, 4).map((item, index) => (
                          <ul key={index}>
                            <li>
                              <div
                                className="category-footer"
                                style={{
                                  cursor: "pointer",
                                  color:
                                    activeCategory === item.slug
                                      ? "gray"
                                      : "inherit",
                                }}
                                onClick={() => handleClickCategory(item.slug)}
                              >
                                {item.title}
                              </div>
                            </li>
                          </ul>
                        ))}
                      <span
                        onClick={() => handleClickMore()}
                        style={{
                          cursor: "pointer",
                          color: activeCategory === "all" ? "gray" : "inherit",
                        }}
                      >
                        More
                      </span>
                    </div>
                  </Col>
                  <Col lg={4} md={6}>
                    <div className="footer-link footer-contact-info">
                      <h6 className="footer-title-contact">Contact</h6>
                      <ul className="icon-link-footer">
                        <li>
                          <LiaPhoneSolid className="icon" />
                          <div>
                            <span> Hotline 24/7:</span>
                            <Link to="tel:+61 7492-4277" className="phone">
                              {footerContact.phone}
                            </Link>
                          </div>
                        </li>
                        <li>
                          <AiOutlineHome className="icon" />
                          <span>{footerContact.address}</span>
                        </li>
                        <li>
                          <HiOutlineEnvelope className="icon" />
                          <Link to="mailto:support@danfe.com.au">
                            {footerContact.email}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer-bottom">
          <Container>
            <div className="footer-bottom-content d-flex justify-content-center">
              <p style={{ color: "#111111" }}>
                ©2023 Danphe Traders. All rights reserved
              </p>
            </div>
          </Container>
        </div>
      </footer>
    </>
  );
};
