
import { axiosInstance, getTokenFromStorage } from "../Api";

export const getChangePassword = async (userData) => {

  const params = new URLSearchParams();
  params.set("current_password", userData.currentpassword);
  params.set("new_password", userData.newpassword);
  params.set("confirm_password", userData.confirmpassword);

  try {
    const api_token = getTokenFromStorage();
 
    const changePassword = await axiosInstance.post(
      `/change-password?${params?.toString()}`
    );
    console.log("change password:", changePassword);

    if (changePassword.status === 200) {
      return { success: true };
    } else {
      throw new Error("An error occurred. Please try again later.");
    }
    // return changePassword.data;
  } catch (err) {
    console.log(err);
    if (err.response && err.response.status === 400) {
      throw new Error("Password does not match");
    }

    console.log(err);
    throw err;
  }
};
