import React from "react";
import { Link } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import { Col, Container, Row } from "react-bootstrap";

import "./submitpage.css";

const SubmitEnquiry = () => {

  return (
    <div>
      <section className="section-padding submit-section">
        <Container>
          <Row>
            <Col sm={10} md={8} lg={6}>
              <div className="submit-wrap">
                <div className="img-submit">
                  <img src="/images/submitimage.png" alt="error" />
                </div>
                <div className="enquire-content">
                  <h2 className="title">Enquiry Request Submitted!</h2>
                  <p>
                    Please wait till you get the response form our team. We will
                    get in touch with your shortly.
                  </p>
                  <Row className="submit-row">
                    <Col sm={6} className="max-width-576">
                      <Link to="/" className="cl-button gray-button w-100">
                        <FiChevronLeft className="icon-left" /> Back to Home
                      </Link>
                    </Col>
                    
                    <Col sm={6}>
                      <Link
                        to="/allproduct"
                        className="cl-button enquiry-btn w-100"
                      >
                        Continue Enquiry
                      </Link>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default SubmitEnquiry;
