import { axiosInstance } from "../Api";

export const getProductDetailApi = async (slug) => {
  try {
    const ProductDetailResponse = await axiosInstance.get(
      `/product-detail?slug=${slug}`
    );
    return ProductDetailResponse.data;
  } catch (err) {
    console.log("getProductDetailApi failed:", err);
  }
};
